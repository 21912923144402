import { FunctionComponent, ReactNode } from 'react';
import './style.scss';

interface MainWithStepsProps {
    children: ReactNode;
    textContext?: string;
    title?: string;
}

const MainWithSteps: FunctionComponent<MainWithStepsProps> = ({ children, textContext, title }) => (
    <div className="main-steps">
        <div className="main-steps__header">
            <span>{textContext}</span>
            <h1 className="title">{title}</h1>
        </div>
        {children}
    </div>
);

export default MainWithSteps;
