import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/store';
import './style.scss';

const DescriptionAccount = () => {
    const { t } = useTranslation();
    const { surname } = useAppSelector(({ user }) => user!);

    return (
        <div className="descriptionAccount">
            <h3 className="title title--small">{t('menu.title', { surname })}</h3>
            <p>{t('menu.account.description')}</p>
            <img alt="freya" src="/assets/freya_illustration.svg" />
        </div>
    );
};

export default DescriptionAccount;
