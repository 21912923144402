import { FunctionComponent } from 'react';
import Button from 'components/common/Button';
import { shareOnSocialNetwork, SocialNetworks } from 'utils/shareOnSocialNetwork';

interface ShareButtonProps {
    afterOnClick: VoidFunction;
    buttonContent: string;
    shareUrl: string;
    socialNetwork: SocialNetworks;
}

const ShareButton: FunctionComponent<ShareButtonProps> = ({ afterOnClick, buttonContent, shareUrl, socialNetwork }) => (
    <Button
        classes="button--black"
        onClick={() => {
            shareOnSocialNetwork(socialNetwork, shareUrl);
            afterOnClick();
        }}
        text={buttonContent}
    />
);

export default ShareButton;
