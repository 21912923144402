import { FunctionComponent } from 'react';
import './style.scss';

interface ChoiceFieldProps {
    id: string;
    label?: string;
    styleLabel: string;
    type: 'radio' | 'checkbox';
    input: {
        checked: boolean;
        onChange: VoidFunction;
        value: string | number;
    };
}

const ChoiceField: FunctionComponent<ChoiceFieldProps> = ({
    input: { checked, ...otherInputProps },
    id,
    label,
    styleLabel = '',
    type,
}) => (
    <label
        className={`choicefield
            ${styleLabel}
            ${checked ? 'choicefield--active' : null}`}
        htmlFor={id}
    >
        {label}
        <input {...otherInputProps} checked={checked} id={id} type={type} />
    </label>
);

export default ChoiceField;
