import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import RestitutionActions from 'components/Restitution/RestitutionActions';

interface DescriptionResultsProps {
    formId: string;
    onOpenPdfModalClick: VoidFunction;
}

const DescriptionResults: FunctionComponent<DescriptionResultsProps> = ({ formId, onOpenPdfModalClick }) => {
    const { t } = useTranslation();

    return (
        <>
            <h3 className="title title--small">{t('results.description.title')}</h3>
            <p>{t('results.description.text')}</p>
            <RestitutionActions formId={formId} onDownloadPdfClick={onOpenPdfModalClick} />
        </>
    );
};

export default DescriptionResults;
