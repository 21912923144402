import { FunctionComponent, ReactNode } from 'react';
import './style.scss';

interface MainWithCardProps {
    children: ReactNode;
    header?: ReactNode;
    title?: string;
    withSmallCard?: boolean;
}

const MainWithCard: FunctionComponent<MainWithCardProps> = ({ children, header, title, withSmallCard = false }) => (
    <>
        <div className="main-card__header">{header}</div>
        <h1 className="title main-card__title">{title}</h1>
        <div className={`main-card ${withSmallCard ? 'main-card--small' : ''}`}>{children}</div>
    </>
);

export default MainWithCard;
