import { FunctionComponent, HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactSlider from 'react-slider';
import { Field, FormErrors, InjectedFormProps, reduxForm } from 'redux-form';
import ChoiceField from 'components/common/form/ChoiceField';
import RotiQuestion from 'components/common/form/RotiQuestion';
import SelectField from 'components/common/form/SelectField';
import { useAppSelector } from 'hooks/store';
import { languageSelector } from 'store/slices/language';
import {
    AGE_CAT,
    DIPLOMA_LIST,
    GENDER_LIST,
    JOBS_LIST,
    REASONS_LIST,
    ROTI_QUESTIONS,
    STATUS_LIST,
} from 'utils/constants';
import { getAllLangs } from 'utils/lang';
import './style.scss';

type SocioDemoFormFieldsError = Partial<Omit<Record<keyof SocioDemoFormFields, string>, 'rotiForm' | 'reasons'>> & {
    rotiForm: Partial<Record<keyof SocioDemoFormFields['rotiForm'], string>>;
    reasons: Partial<Record<keyof SocioDemoFormFields['reasons'], string>>;
};

const validate = (values: Partial<SocioDemoFormFields>): FormErrors => {
    const errors: SocioDemoFormFieldsError = {
        reasons: {},
        rotiForm: {},
    };

    if (!values.gender) {
        errors.gender = 'Required';
    }

    if (!values.native_language) {
        errors.native_language = 'Required';
    }
    if (!values.status) {
        errors.status = 'Required';
    }
    if (!values.diploma) {
        errors.diploma = 'Required';
    }
    if (!values.reasons || (values.reasons && !Object.values(values.reasons).includes(true))) {
        errors.reasons.knowledge = 'Required';
    }
    if (!values.rotiForm?.satisfaction) {
        errors.rotiForm.satisfaction = 'Required';
    }
    if (!values.rotiForm?.difficulty) {
        errors.rotiForm.difficulty = 'Required';
    }
    if (!values.rotiForm?.aestheticism) {
        errors.rotiForm.aestheticism = 'Required';
    }
    if (!values.rotiForm?.duration) {
        errors.rotiForm.duration = 'Required';
    }
    if (!values.rotiForm?.content) {
        errors.rotiForm.content = 'Required';
    }

    return errors;
};

export interface SocioDemoFormFields {
    ageRange: number;
    gender: string;
    native_language: string;
    status: string;
    diploma: string;
    reasons: Record<string, boolean>;
    job: string;
    rotiForm: {
        satisfaction: boolean;
        aestheticism: boolean;
        duration: boolean;
        content: boolean;
        difficulty: boolean;
    };
}

/* eslint-disable react/prop-types */
// This rule is mandatory to make the app compile, don't know why ESLint gives these errors as there is no PropTypes declarations anywhere ¯\_(ツ)_/¯

const SocioDemoForm: FunctionComponent<InjectedFormProps<SocioDemoFormFields>> = ({
    initialValues,
    invalid,
    handleSubmit,
}) => {
    const { t } = useTranslation();
    const { language } = useAppSelector(languageSelector);
    const { formCompleted } = useAppSelector(({ user }) => user!);

    if (initialValues?.ageRange === null) {
        /* eslint-disable-next-line no-param-reassign */
        initialValues.ageRange = 1; // set default value to ageRange
    }

    const renderTooltip = (props: HTMLProps<HTMLDivElement>, state: { valueNow: number }) => (
        <div {...props}>
            <div className="slider-form__value">{AGE_CAT[state.valueNow].toString()}</div>
        </div>
    );

    return (
        <form method="post" onSubmit={handleSubmit}>
            {!formCompleted && (
                <>
                    <h2 className="subheading">{t('sociodemo.you')}</h2>
                    <div className="form-group">
                        <p className="label">{t('sociodemo.field.age.title')}</p>
                        <Field
                            // TODO PCD: Refacto to delete eslint-disable
                            // eslint-disable-next-line react/no-unstable-nested-components
                            component={({
                                input: { onChange, value },
                            }: {
                                input: { value: number; onChange: (newValue: number) => void };
                            }) => (
                                <ReactSlider
                                    className="slider-form"
                                    defaultValue={value}
                                    markClassName="slider-form__mark"
                                    max={5}
                                    min={0}
                                    onAfterChange={(v) => onChange(v)}
                                    renderThumb={(props, state) => renderTooltip(props, state)}
                                    thumbClassName="slider-form__thumb"
                                    trackClassName="slider-form__track"
                                />
                            )}
                            defaultValue="1"
                            format={null}
                            id="age"
                            name="ageRange"
                        />
                    </div>
                    <div className="form-group">
                        <p className="label">{t('sociodemo.field.gender.title')}</p>
                        <div className="gender__wrapper">
                            {GENDER_LIST.map((status, i) => (
                                <Field
                                    key={status.key}
                                    component={ChoiceField}
                                    id={`gender-${i}`}
                                    label={t(status.name)}
                                    name="gender"
                                    options={GENDER_LIST}
                                    type="radio"
                                    value={status.key}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <p className="label">{t('sociodemo.field.status.title')}</p>
                        <div className="choicefield__wrapper">
                            {STATUS_LIST.map((status, i) => (
                                <Field
                                    key={status.key}
                                    component={ChoiceField}
                                    id={`status-${i}`}
                                    label={t(status.name)}
                                    name="status"
                                    type="radio"
                                    value={status.key}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <p className="label">{t('sociodemo.field.diploma.title')}</p>
                        <div className="choicefield__wrapper">
                            {DIPLOMA_LIST.map((status, i) => {
                                if ('de' === language && 'engineer' === status.key) {
                                    return <div />;
                                }

                                return (
                                    <Field
                                        key={status.key}
                                        component={ChoiceField}
                                        id={`diploma-${i}`}
                                        label={t(status.name)}
                                        name="diploma"
                                        type="radio"
                                        value={status.key}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="form-group">
                        {/* eslint-disable-next-line react/no-danger */}
                        <p dangerouslySetInnerHTML={{ __html: t('sociodemo.field.reason.title') }} className="label" />
                        <div className="choicefield__wrapper">
                            {REASONS_LIST.map((status, i) => (
                                <Field
                                    key={status.key}
                                    component={ChoiceField}
                                    id={`reason-${i}`}
                                    label={t(status.name)}
                                    name={`reasons.${status.key}`}
                                    type="checkbox"
                                    value={status.key}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <Field
                            component={SelectField}
                            label={t('sociodemo.field.native.title')}
                            name="native_language"
                            options={[
                                ...getAllLangs().map(({ keyLang, label }) => ({ value: keyLang, label })),
                                { value: 'other', label: t('sociodemo.field.native.option_other') },
                            ]}
                            placeholder={t('sociodemo.field.native.placeholder')}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <Field
                            component={SelectField}
                            label={t('sociodemo.field.job.title')}
                            name="job"
                            options={JOBS_LIST.map(({ key, name }) => ({ value: key, label: t(name) }))}
                            placeholder={t('sociodemo.job.placeholder')}
                        />
                    </div>
                </>
            )}
            <div className="form-group">
                <h2 className="subheading">{t('sociodemo.satisfaction')}</h2>
                {ROTI_QUESTIONS.map((rotiQuestion) => (
                    <RotiQuestion key={rotiQuestion} label={rotiQuestion} />
                ))}
            </div>
            <button
                className="button button--black button--center button--block-mobile"
                disabled={invalid}
                type="submit"
            >
                {t('sociodemo.submit')}
            </button>
        </form>
    );
};

export default connect()(
    reduxForm<SocioDemoFormFields>({
        form: 'profilingForm',
        validate,
    })(SocioDemoForm)
);
