import { FunctionComponent } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import PauseConfirmation from 'components/PauseConfirmation';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import './style.scss';

interface NavigationProps {
    current: number;
    disableBack?: boolean;
    displayBar?: boolean;
    onBack: VoidFunction;
    total: number | null;
}

const Navigation: FunctionComponent<NavigationProps> = ({ current, disableBack, displayBar, onBack, total }) => {
    const push = useLocalizedHistoryPush();
    const renderCurrentQuestion = (num: number) => (num < 10 ? `0${num}` : num);

    const percentage = `${total ? (current / total) * 100 : 0}%`;

    const onPause = () => {
        confirmAlert({
            /* eslint-disable-next-line */
            customUI: ({ onClose }) => <PauseConfirmation onClose={onClose} push={push} />,
        });
    };

    return (
        <div className={`nav ${displayBar ? '' : 'nav--number'}`}>
            <button
                className={`nav__button ${disableBack ? 'nav__button--disabled' : ''}`}
                disabled={disableBack}
                onClick={onBack}
                type="button"
            >
                <img alt="Précédent" src="/assets/icons/back.svg" />
            </button>
            {displayBar ? (
                <div className="bar">
                    <div
                        className="bar__content"
                        style={{
                            width: percentage,
                        }}
                    />
                </div>
            ) : (
                <div className="question">
                    <span className="question__current">{renderCurrentQuestion(current)}</span>
                    {total && <span className="question__total">/{total}</span>}
                </div>
            )}
            <button className="nav__button" onClick={onPause} type="button">
                <img alt="Pause" src="/assets/icons/break.svg" />
            </button>
        </div>
    );
};

export default Navigation;
