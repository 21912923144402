import { createContext, FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react';
import webviewDetection from 'utils/webviewDetection';

export interface AppDataInterface {
    API_URL: string;
    AUTH_URL: string;
    COOKIE_CLIENT_ID: string;
    FREYA_URL: string;
    HESTER_URL: string;
    INNOVATION_SITE_URL: string;
    MARCO_V3_URL: string;
    VASCO_V2_URL: string;
}

interface AppDataContextInterface extends AppDataInterface {
    isWebview: boolean;
}

const AppDataContext = createContext<AppDataContextInterface>(undefined!);

const defaultCtx = {
    isWebview: false,
};

export const useAppData = () => useContext<AppDataContextInterface>(AppDataContext);

export const AppDataProvider: FunctionComponent<{ appData: AppDataInterface; children: ReactNode }> = ({
    children,
    appData,
}) => {
    const [state, setState] = useState<AppDataContextInterface>({
        ...defaultCtx,
        ...appData,
    });

    useEffect(() => {
        function getEnvironment() {
            const isWebview = webviewDetection();

            setState({
                ...state,
                isWebview,
            });
        }

        getEnvironment();
    }, []);

    return <AppDataContext.Provider value={state}>{children}</AppDataContext.Provider>;
};
