import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

interface FooterProps {
    createdAt?: string;
    pageNb?: number;
    user: {
        name: string;
        surname: string;
    };
}
const Footer: FunctionComponent<FooterProps> = ({ createdAt, user, pageNb }) => {
    const { t } = useTranslation();

    return (
        <div className="pdf__footer">
            <span>
                <span className="bold">
                    {user.surname}
                    &nbsp;
                    {user.name}
                </span>
                &nbsp;-&nbsp;
                {createdAt}
                &nbsp;-&nbsp;
                {t('pdf.footer.text')}
            </span>
            <span>{pageNb}</span>
        </div>
    );
};

export default Footer;
