import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './style.scss';

interface PageSummaryProps {
    dimensionKey: string;
    subDimensionKey1: string;
    subDimensionKey2: string;
    subDimensionKey3: string;
}

const PageSummary: FunctionComponent<PageSummaryProps> = ({
    dimensionKey,
    subDimensionKey1,
    subDimensionKey2,
    subDimensionKey3,
}) => {
    const { t } = useTranslation();

    const dimension = t(`restitution.${dimensionKey}.title2`).toLowerCase();
    const subDimension1 = t(`pdf.subresult.${subDimensionKey1}.quickDescription`);
    const subDimension2 = t(`pdf.subresult.${subDimensionKey2}.quickDescription`);
    const subDimension3 = t(`pdf.subresult.${subDimensionKey3}.quickDescription`);
    const subDimensionString = t('pdf.summaryPage.text1', {
        dimension,
        subDimension1,
        subDimension2,
        subDimension3,
    });

    return (
        <>
            <div className="pdf-summary-page__shape-container">
                <img alt="Summary page shape" src="/assets/pdf-summary-shape.svg" />
            </div>
            <div className="pdf-summary-page__container">
                <h2>{t('pdf.summaryPage.title')}</h2>
                <p className="with-margin-bottom">{subDimensionString}</p>
                <p>{t('pdf.summaryPage.progress')}</p>
                <p className="with-margin-bottom">{t('pdf.summaryPage.text2')}</p>
                <p>{t('pdf.summaryPage.text3')}</p>

                <h2 className="with-margin-top">{t('pdf.summaryPage.subtitle')}</h2>
                <p>
                    <Trans i18nKey="pdf.summaryPage.text4" />
                </p>
                <p className="with-margin-bottom">{t('pdf.summaryPage.text5')}</p>
                <p className="with-margin-bottom">
                    <Trans i18nKey="pdf.summaryPage.text6" />
                </p>
                <p className="with-margin-bottom">
                    <Trans i18nKey="pdf.summaryPage.text7" />
                </p>
                <p>{t('pdf.summaryPage.final')}</p>
            </div>
        </>
    );
};

export default PageSummary;
