import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/common/Modal';
import ShareButton from 'components/common/ShareButton';
import { useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { languageSelector } from 'store/slices/language';
import { SocialNetworks } from 'utils/shareOnSocialNetwork';
import './style.scss';

interface ShareModalProps {
    formId: string;
    handleClose: VoidFunction;
}

const ShareModal: FunctionComponent<ShareModalProps> = ({ formId, handleClose }) => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const { language } = useAppSelector(languageSelector);
    const shareUrl = `${API_URL}/share/${formId}/${language}`;

    return (
        <Modal closeModal={handleClose} fullScreen>
            <div className="base-margin share-buttons">
                <ShareButton
                    afterOnClick={() => handleClose()}
                    buttonContent={t('results.description.action.linkedin')}
                    shareUrl={shareUrl}
                    socialNetwork={SocialNetworks.LinkedIn}
                />
                <ShareButton
                    afterOnClick={() => handleClose()}
                    buttonContent={t('results.description.action.facebook')}
                    shareUrl={shareUrl}
                    socialNetwork={SocialNetworks.Facebook}
                />
            </div>
        </Modal>
    );
};

export default ShareModal;
