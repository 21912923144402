/* eslint-disable import/no-cycle */
import { getCookie, setCookie } from './cookies';

/**
 * check for a webview cookie or a webview query string
 * @returns {boolean} true if our webview is detected, false otherwise
 */
function webviewDetection() {
    // if a cookie is set, then trust it
    const webviewCookie = getCookie('webview');
    if (webviewCookie !== '') {
        return true;
    }

    // otherwise, check for a query string and set a cookie
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryStringValue = !!urlSearchParams.get('webview');
    if (queryStringValue) {
        setCookie('webview', queryStringValue);

        return queryStringValue;
    }

    // default case
    return false;
}

export default webviewDetection;
