import langData from 'translations/_lang.json';

export const DEFAULT_LANGUAGE = 'fr';

export const DEFAULT_LANGUAGE_MISSING_PAGE = 'en';

export const getAllLangs = () => langData.languages.sort((a, b) => (a.label < b.label ? -1 : 1));

export const getLangInfos = (key: string) => langData.languages.find((l) => l.keyLang === key);

export const isAValidLanguage = (lang: string) => langData.languages.find((l) => l.keyLang === lang) !== undefined;

export const extractLangFromIso639 = (isoCode: string) => isoCode.split('-')[0];

export const getAllLangKeys = () => langData.languages.map((l) => l.keyLang);
