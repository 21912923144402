import { useAppDispatch } from './store';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { logout } from 'jt-client/actions';
import { cleanForm } from 'store/slices/currentForm';
import { cleanUser } from 'store/slices/user';

const useLogout = () => {
    const dispatch = useAppDispatch();
    const push = useLocalizedHistoryPush();

    return () => {
        push('/');
        dispatch(logout());
        dispatch(cleanForm());
        dispatch(cleanUser());
    };
};

export default useLogout;
