import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { recoverForm } from 'store/slices/currentForm';

const WithCurrentForm: FunctionComponent<{ children: ReactElement }> = ({ children }) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const form = useAppSelector(({ currentFormId }) => currentFormId);
    const { API_URL } = useAppData();

    useEffect(() => {
        (async () => {
            if (!form) {
                await dispatch(recoverForm(API_URL));
            }

            setLoading(false);
        })();
    }, []);

    if (loading) {
        return null;
    }

    return form ? children : <Redirect to="/account" />;
};

export default WithCurrentForm;
