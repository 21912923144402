import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { SubmissionError } from 'redux-form';
import { RootState } from '../store';
import { setOrderProgress } from 'store/slices/progress';
import { Delete, Post } from 'utils/http';

export type Reply = {
    order: number;
    value: number | null;
    question: {
        key: string;
    };
};

type RepliesStateDefined = Reply[];

type RepliesState = RepliesStateDefined | null;

const initialState = null as RepliesState;

export const repliesSlice = createSlice({
    name: 'replies',
    initialState,
    reducers: {
        resetRepliesValues: (state) => {
            if (state) {
                return state.map((el) => ({
                    ...el,
                    value: null,
                }));
            }

            return state;
        },
        setReplies: (state, { payload }: PayloadAction<RepliesStateDefined>) => payload,
        updateReplies: (state, { payload }: PayloadAction<Pick<Reply, 'order' | 'value'>>) => {
            const { order, value } = payload;
            if (state) {
                return state.map((el) =>
                    el.order === order
                        ? {
                              ...el,
                              value,
                          }
                        : el
                );
            }

            return state;
        },
    },
});

export const { updateReplies, setReplies } = repliesSlice.actions;

export const resetRepliesValues =
    (API_URL: string): ThunkAction<void, RootState, void, AnyAction> =>
    async (dispatch, getState) => {
        try {
            const { currentFormId } = getState();

            await Delete(`${API_URL}/form/${currentFormId}/reset`);
            await dispatch(repliesSlice.actions.resetRepliesValues());
        } catch (e) {
            throw new SubmissionError({ _error: 'error.server.unknown' });
        }
    };

export const postReply =
    (API_URL: string, order: number, value: number): ThunkAction<void, RootState, void, AnyAction> =>
    async (dispatch, getState) => {
        try {
            const {
                currentFormId,
                progress: { orderProgress, firstStepComplete },
                replies,
            } = getState();
            await Post(`${API_URL}/reply/${currentFormId}/${order}`, { value });
            dispatch(updateReplies({ order, value }));
            if (orderProgress === order && orderProgress < (replies?.length ?? 0) && !firstStepComplete) {
                dispatch(setOrderProgress(order + 1));
            }
        } catch (e) {
            throw new SubmissionError({ _error: 'error.server.unknown' });
        }
    };

export default repliesSlice.reducer;
