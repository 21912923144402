import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DimensionResult } from 'components/Diagram';
import DimensionFrame from 'components/pdf/common/DimensionFrame';

import './style.scss';

interface PageDimensionHierarchyProps {
    dimensionResults: Array<DimensionResult>;
}

const PageDimensionHierarchy: FunctionComponent<PageDimensionHierarchyProps> = ({ dimensionResults }) => {
    const { t } = useTranslation();

    const sort = (a: DimensionResult, b: DimensionResult) => b.grade - a.grade;

    return (
        <div className="pdf-hierarchy-page__container">
            <h2>{t('pdf.hierarchyPage.title')}</h2>
            <p>{t('pdf.hierarchyPage.subtitle')}</p>
            <div className="pdf-hierarchy-page__dimensions-container">
                {dimensionResults.sort(sort).map(({ dimension, grade }) => (
                    <DimensionFrame
                        key={`frame-${dimension}`}
                        dimensionKey={dimension}
                        percentage={Math.floor(grade * 10)}
                    />
                ))}
            </div>
            <p className="pdf-hierarchy-page__description">{t('pdf.hierarchyPage.description')}</p>
        </div>
    );
};

export default PageDimensionHierarchy;
