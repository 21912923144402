import { Redirect, useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/store';
import { languageSelector } from 'store/slices/language';

const RedirectToLocalizedRoute = () => {
    const { pathname } = useLocation();
    const { language, allowedLanguages } = useAppSelector(languageSelector);

    if (!language || !allowedLanguages.includes(language)) {
        return <Redirect to="/missing-language" />;
    }

    if (pathname === '/') {
        return <Redirect to={`/${language}`} />;
    }

    return <Redirect to={`/${language}${pathname}`} />;
};

export default RedirectToLocalizedRoute;
