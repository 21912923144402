import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ErrorPage from 'components/layout/ErrorPage';
import './style.scss';

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <ErrorPage title={t('404.title')}>
            <div className="not-found-button__wrapper">
                <Link className="not-found-button" to="/">
                    {t('404.content')}
                </Link>
            </div>
        </ErrorPage>
    );
};

export default NotFound;
