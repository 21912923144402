import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

const ValueExplanation: FunctionComponent<{ dimensionKey: string }> = ({ dimensionKey }) => {
    const { t } = useTranslation();

    const description = t(`pdf.valueExplanation.${dimensionKey}.description`);

    return (
        <div className="pdf-value-explanation__container">
            <div className={`pdf-value-explanation__heading pdf-value-explanation__heading--${dimensionKey}`}>
                <span className="pdf-value-explanation__heading-name">
                    {t(`restitution.${dimensionKey}.shortTitle`)}
                </span>
                <span className="pdf-value-explanation__heading-text">{t(`restitution.${dimensionKey}.title2`)}</span>
            </div>
            <div className="pdf-value-explanation__content">
                <p>{description}</p>
                <p className="title">{t('pdf.valueExplanation.subpart1')}</p>
                <p>{t(`pdf.valueExplanation.${dimensionKey}.organizationType`)}</p>
                <p className="title">{t('pdf.valueExplanation.subpart2')}</p>
                <p>{t(`pdf.valueExplanation.${dimensionKey}.needs`)}</p>
            </div>
        </div>
    );
};

export default ValueExplanation;
