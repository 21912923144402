import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import MainWithCard from 'components/layout/MainWithCard';
import Panel from 'components/layout/Panel';
import ProfilingForm, { SocioDemoFormFields } from 'components/SocioDemoForm';
import DescriptionForm from 'components/StepDescription/DescriptionForm';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import WithCurrentForm from 'providers/WithCurrentForm';
import WithGradeEqualities from 'providers/WithGradeEqualities';
import { updateUser } from 'store/slices/user';

const Form = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const push = useLocalizedHistoryPush();
    const { API_URL } = useAppData();
    const { secondStepComplete } = useAppSelector(({ progress }) => progress);

    const submitForm = async (values: SocioDemoFormFields) => {
        await dispatch(updateUser(API_URL, values));
    };

    const goToResults = () => {
        push('/results');
    };

    if (!secondStepComplete) {
        return <Redirect to="/account" />;
    }

    return (
        <Container>
            <Panel>
                <DescriptionForm />
            </Panel>
            <Main>
                <MainWithCard title={t('sociodemo.title')}>
                    <ProfilingForm onSubmit={submitForm} onSubmitSuccess={goToResults} />
                </MainWithCard>
            </Main>
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm>
        <WithGradeEqualities>
            <Form {...props} />
        </WithGradeEqualities>
    </WithCurrentForm>
);
