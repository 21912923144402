import { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import './style.scss';

interface BackdropProps {
    children: ReactNode;
    onClick?: MouseEventHandler<HTMLDivElement> | undefined;
    zIndex?: number;
}

const Backdrop: FunctionComponent<BackdropProps> = ({ children, onClick, zIndex }) => (
    <div aria-hidden="true" className="backdrop" onClick={onClick} style={{ zIndex }}>
        {children}
    </div>
);

export default Backdrop;
