import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import SelectField from 'components/common/form/SelectField';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import useUserLanguage from 'hooks/useUserLanguage';
import { languageSelector, setLanguage } from 'store/slices/language';
import { updateUserLanguage } from 'store/slices/user';
import { getAllLangs } from 'utils/lang';

import './style.scss';

interface LanguageSwitcherProps {
    className?: string;
    color?: 'black' | 'white';
    label?: string;
    shortLabel?: boolean;
}

const LanguageSwitcher: FunctionComponent<LanguageSwitcherProps> = ({
    className,
    color = 'white',
    label,
    shortLabel = false,
}) => {
    const { API_URL } = useAppData();
    const dispatch = useAppDispatch();
    const userLanguage = useUserLanguage();
    const { push } = useHistory();
    const languages = getAllLangs();
    const { language, allowedLanguages } = useAppSelector(languageSelector);

    let defaultValue = language;
    if (userLanguage) {
        defaultValue = allowedLanguages.includes(userLanguage) ? userLanguage : null;
    }

    const onChangeLanguage = (newLanguage: string) => {
        if (userLanguage) {
            dispatch(updateUserLanguage(API_URL, newLanguage));
        } else {
            dispatch(setLanguage(newLanguage));
            push(`/${newLanguage}`);
        }
    };

    return (
        <div className={`${className} language-switcher language-switcher--${color}`}>
            <SelectField
                input={{
                    name: 'userLanguage',
                    onChange: (value) => onChangeLanguage(value),
                    value: defaultValue,
                }}
                label={label}
                labelHidden={!label}
                menuPlacement={shortLabel ? 'top' : 'bottom'}
                options={languages.map(({ keyLang, label: labelLng }) => ({
                    value: keyLang,
                    label: shortLabel ? keyLang.toUpperCase() : labelLng,
                }))}
            />
        </div>
    );
};

export default LanguageSwitcher;
