import { Redirect } from 'react-router-dom';
import { useAppSelector } from 'hooks/store';
import { languageSelector } from 'store/slices/language';
import { USER_ROLES } from 'utils/constants';

const LoginRedirect = () => {
    const { language } = useAppSelector(languageSelector);
    const { id, status } = useAppSelector(({ auth }) => auth);
    if (id) {
        if (USER_ROLES.ADMIN === status) {
            return <Redirect to={`/${language}/admin`} />;
        }

        return <Redirect to={`/${language}/account`} />;
    }

    return <Redirect to={`/${language}`} />;
};

export default LoginRedirect;
