import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import { RootState } from 'store/store';
import { getAllLangs } from 'utils/lang';
import { required } from 'utils/validators';

import './style.scss';

interface PdfModalProps {
    onClose: VoidFunction;
}

interface PdfModalFields {
    language: string;
}

const PdfModal: FunctionComponent<InjectedFormProps<PdfModalFields, PdfModalProps> & PdfModalProps> = ({
    onClose,
    handleSubmit,
}) => {
    const { t } = useTranslation();
    const languages = getAllLangs();

    return (
        <Modal closeModal={onClose} fullScreen>
            <form onSubmit={handleSubmit}>
                <div className="pdfModal__language">
                    <p className="label">{t('pdfModal.field.language.title')}</p>
                    <Field component="select" name="language" validate={[required]}>
                        {languages.map(({ keyLang, label }) => (
                            <option key={keyLang} value={keyLang}>
                                {label}
                            </option>
                        ))}
                    </Field>
                </div>
            </form>
            <Button
                classes="button--black button--block base-margin"
                onClick={handleSubmit}
                text={t('results.description.action.download')}
                submit
            />
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    initialValues: { language: state.user?.language ?? '' },
    enableReinitialize: true,
});

export default connect(mapStateToProps)(reduxForm<PdfModalFields, PdfModalProps>({ form: 'pdfForm' })(PdfModal));
