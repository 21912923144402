import { FunctionComponent, ReactNode } from 'react';
import Footer from 'components/pdf/PageLayout/Footer/index';
import './style.scss';

interface PageLayoutProps {
    backPage?: boolean;
    children?: ReactNode;
    createdAt?: string;
    page: number;
    user: {
        name: string;
        surname: string;
    };
}

const PageLayout: FunctionComponent<PageLayoutProps> = ({ backPage = false, createdAt, page, children, user }) => (
    <div className={backPage ? 'pdf__container backpage' : 'pdf__container'}>
        <div className="pdf__content">{children}</div>
        <Footer createdAt={createdAt} pageNb={page} user={user} />
    </div>
);

export default PageLayout;
