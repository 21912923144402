import { useTranslation } from 'react-i18next';

const DescriptionForm = () => {
    const { t } = useTranslation();

    return (
        <>
            <h3 className="title title--small">{t('sociodemo.description.title')}</h3>
            <p>{t('sociodemo.description.text1')}</p>
            <p
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: t('sociodemo.description.text2') }}
            />
            <p
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: t('sociodemo.description.text3') }}
            />
        </>
    );
};

export default DescriptionForm;
