import { FunctionComponent, MouseEventHandler } from 'react';
import '../style.scss';

interface GameCardProps {
    disabled?: boolean;
    num?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    position?: string;
    selected?: boolean;
    text?: string;
}

const GameCard: FunctionComponent<GameCardProps> = ({ disabled, num, onClick, position, text, selected }) => (
    <button
        className={`
            card-game__shadow
            card-game__shadow--${position}
            ${selected && !disabled ? 'card-game__shadow--selected' : ''}
            ${disabled ? 'card-game__shadow--disabled' : ''}
        `}
        disabled={disabled}
        onClick={onClick}
        type="button"
    >
        <div className="card-game__card">
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: text ?? '' }} className="card-game__text" />
            <span className="card-game__num">{num}</span>
        </div>
    </button>
);

export default GameCard;
