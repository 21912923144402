import { useAppDispatch } from '../../../hooks/store';
import { setMenuOpen } from '../../../store/slices/menu';
import './style.scss';

const MenuButton = () => {
    const dispatch = useAppDispatch();

    return (
        <button className="menu-button" onClick={() => dispatch(setMenuOpen(true))} type="button">
            <img alt="Menu" src="/assets/icons/burger.svg" />
        </button>
    );
};

export default MenuButton;
