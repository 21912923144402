import { Trans, useTranslation } from 'react-i18next';

import './style.scss';

const PageContentPresentation = () => {
    const { t } = useTranslation();

    return (
        <div className="pdf-content-presentation__container">
            <div className="pdf-content-presentation__heading">
                <h2>{t('pdf.contentPresentation.title')}</h2>
                <img alt="Content presentation shape" src="/assets/pdf-content-presentation-shape.svg" />
            </div>
            <p>{t('pdf.contentPresentation.subtitle')}</p>

            <div className="pdf-content-presentation__title-container">
                <img alt="First bullet point" src="/assets/pdf-content-presentation-bullet-point-1.svg" />
                <h3>{t('pdf.contentPresentation.results.title')}</h3>
            </div>
            <ul>
                <li>
                    <Trans i18nKey="pdf.contentPresentation.results.profil" />
                </li>
                <li>
                    <Trans i18nKey="pdf.contentPresentation.results.profil.detail" />
                </li>
                <li>
                    <Trans i18nKey="pdf.contentPresentation.results.summary" />
                </li>
            </ul>
            <div className="pdf-content-presentation__title-container">
                <img alt="Second bullet point" src="/assets/pdf-content-presentation-bullet-point-2.svg" />
                <h3>{t('pdf.contentPresentation.model.title')}</h3>
            </div>
            <ul>
                <li>
                    <Trans i18nKey="pdf.contentPresentation.model.annex" />
                </li>
            </ul>
        </div>
    );
};

export default PageContentPresentation;
