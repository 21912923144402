import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import Navigation from 'components/common/Navigation';
import ExerciseOne from 'components/ExerciseOne';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import MainWithSteps from 'components/layout/MainWithSteps';
import Panel from 'components/layout/Panel';
import PanelDescription from 'components/StepDescription/PanelDescription';
import { useAppSelector } from 'hooks/store';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import WithCurrentForm from 'providers/WithCurrentForm';

const StepOne = () => {
    const { t } = useTranslation();
    const push = useLocalizedHistoryPush();
    const questions = useAppSelector(({ replies }) => replies);
    const { orderProgress, firstStepComplete } = useAppSelector(({ progress }) => progress);
    const { order } = useParams<{ order: string }>();

    if (firstStepComplete) {
        return <Redirect to="/interstep" />;
    }

    if (orderProgress && parseInt(order, 10) > orderProgress) {
        return <Redirect to={`/step/first/${orderProgress}`} />;
    }

    const onBack = () => {
        push(`/step/first/${parseInt(order, 10) - 1}`);
    };

    return (
        <Container>
            <Panel>
                <PanelDescription step="step1" />
            </Panel>
            <Main>
                <MainWithSteps textContext={t('step1.title.text1')} title={t('step1.title.text2')}>
                    <ExerciseOne order={parseInt(order, 10)} />
                    <Navigation
                        current={parseInt(order, 10)}
                        disableBack={parseInt(order, 10) === 1}
                        onBack={onBack}
                        total={questions ? questions.length : null}
                        displayBar
                    />
                </MainWithSteps>
            </Main>
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm>
        <StepOne {...props} />
    </WithCurrentForm>
);
