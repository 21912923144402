import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import Button from 'components/common/Button';
import SelectField from 'components/common/form/SelectField';
import Spinner from 'components/common/Spinner';
import ExportForm, { ExportFormFields } from 'components/ExportForm';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import MainWithCard from 'components/layout/MainWithCard';
import Panel from 'components/layout/Panel';
import DescriptionAdmin from 'components/StepDescription/DescriptionAdmin';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { exportForms } from 'store/actions/admin';
import { USER_ROLES } from 'utils/constants';
import { Get } from 'utils/http';
import { getAllLangs } from 'utils/lang';
import './style.scss';

interface StatsInterface {
    nbUser: number;
    nbCompletedForm: number;
    nbUserLastSevenDays: number;
    nbCompletedFormLastSevenDays: number;
}

const Admin = () => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const [stats, setStats] = useState<StatsInterface | null>(null);
    const [language, setLanguage] = useState('');
    const history = useHistory();
    const dispatch = useAppDispatch();
    const isAdmin = useAppSelector(({ auth }) => auth.status === USER_ROLES.ADMIN);
    const onExport = (values: ExportFormFields) => dispatch(exportForms(API_URL, values));

    const onGoBack = () => history.goBack();

    if (!isAdmin) {
        return <Redirect to="/account" />;
    }

    useEffect(() => {
        (async () => {
            const response = await Get(`${API_URL}/admin/stats${language ? `?language=${language}` : ''}`);
            setStats(response);
        })();
    }, [language]);

    return (
        <Container>
            <Panel>
                <DescriptionAdmin />
            </Panel>
            <Main className="admin">
                <MainWithCard
                    header={
                        <Button
                            classes="button--transparent admin__back-button"
                            img="/assets/icons/white-back-arrow.svg"
                            onClick={onGoBack}
                            text={t('common.legalPage.back')}
                        />
                    }
                >
                    <h1 className="admin__title">{t('admin.title')}</h1>
                    {stats ? (
                        <>
                            <SelectField
                                input={{
                                    name: 'statLanguage',
                                    onChange: (value) => setLanguage(value),
                                    value: language,
                                }}
                                label={t('admin.stat.language')}
                                options={getAllLangs().map(({ keyLang, label }) => ({
                                    value: keyLang,
                                    label,
                                }))}
                                hasAllOption
                            />
                            <ul className="admin__list">
                                <li>{`${t('admin.stat1')}${stats.nbUser}`}</li>
                                <li>{`${t('admin.stat2')}${stats.nbUserLastSevenDays}`}</li>
                                <li>{`${t('admin.stat3')}${stats.nbCompletedForm}`}</li>
                                <li>{`${t('admin.stat4')}${stats.nbCompletedFormLastSevenDays}`}</li>
                            </ul>
                            <ExportForm onSubmit={onExport} />
                        </>
                    ) : (
                        <div className="admin__spinner">
                            <Spinner color="black" />
                        </div>
                    )}
                </MainWithCard>
            </Main>
        </Container>
    );
};

export default Admin;
