import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import ShareModal from 'components/Restitution/ShareModal';
import useWindowSize from 'hooks/useWindowSize';
import './style.scss';

interface RestitutionActionsProps {
    formId: string;
    onDownloadPdfClick?: VoidFunction;
}

const RestitutionActions: FunctionComponent<RestitutionActionsProps> = ({ formId, onDownloadPdfClick }) => {
    const isMobile = 'xs' === useWindowSize().size;
    const { t } = useTranslation();
    const [isOpenShareModal, setIsOpenShareModal] = useState(false);

    return (
        <div className="restitution-actions">
            {isOpenShareModal && <ShareModal formId={formId} handleClose={() => setIsOpenShareModal(false)} />}
            <Button
                classes={`button--${isMobile ? 'black' : 'white'} button--block base-margin`}
                img={isMobile ? undefined : '/assets/icons/download.svg'}
                onClick={onDownloadPdfClick}
                text={t('results.description.action.download')}
            />
            <Button
                classes="button--black button--block base-margin button--small-padding share-button"
                img={isMobile ? undefined : '/assets/icons/share.svg'}
                onClick={() => setIsOpenShareModal(true)}
                text={t('results.description.action.share')}
            />
        </div>
    );
};

export default RestitutionActions;
