import { FunctionComponent, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/common/Spinner';
import GameCard from 'components/ExerciseTwo/GameCard';
import DescriptionExercise from 'components/StepDescription/DescriptionExercise';
import Instructions from 'components/StepDescription/Instructions';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { answerComparison, postComparison, startEqualityOrdering } from 'store/slices/equalityOrdering';
import { completeSecondStep } from 'store/slices/progress';
import './style.scss';

const ExerciseTwo: FunctionComponent<{ order: number }> = ({ order }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const push = useLocalizedHistoryPush();
    const { API_URL } = useAppData();
    const [isLoading, setIsLoading] = useState(false);
    const { questionToOrder } = useAppSelector(({ equalityOrdering }) => equalityOrdering);
    const gradeEqualitiesLength = useAppSelector(({ gradeEqualities }) => gradeEqualities.length);
    const nextComparison = useAppSelector(({ equalityOrdering: { questionToCompare } }) => questionToCompare);

    const showInstructions = () => {
        confirmAlert({
            // TODO PCD: Refacto to delete eslint-disable
            // eslint-disable-next-line react/no-unstable-nested-components
            customUI: ({ onClose }) => (
                <Instructions onClose={onClose}>
                    <DescriptionExercise step="step2" />
                </Instructions>
            ),
        });
    };

    useEffect(() => {
        dispatch(startEqualityOrdering(order));
    }, [order]);

    useEffect(() => {
        if (order === 1) {
            showInstructions();
        }
    }, [order]);

    const goNext = async () => {
        setIsLoading(true);
        await dispatch(postComparison(order, API_URL));
        setIsLoading(false);
        if (gradeEqualitiesLength === order) {
            dispatch(completeSecondStep());
            push('/final');
        } else {
            push(`/step/second/${order + 1}`);
        }
    };

    return (
        <>
            <div className="card-game__wrapper">
                <GameCard
                    disabled={isLoading}
                    num="I"
                    onClick={(e) => {
                        dispatch(answerComparison(true, goNext));
                        e.currentTarget.blur();
                    }}
                    position="left"
                    text={t(`question.${questionToOrder}`)}
                />
                <GameCard
                    disabled={isLoading}
                    num="II"
                    onClick={(e) => {
                        dispatch(answerComparison(false, goNext));
                        e.currentTarget.blur();
                    }}
                    position="right"
                    text={t(`question.${nextComparison}`)}
                />
            </div>
            {isLoading && <Spinner />}
        </>
    );
};

export default ExerciseTwo;
