import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import SsoBanner from '../SsoBanner';
import Button from 'components/common/Button';
import CheckboxField from 'components/common/form/CheckboxField';
import SimpleField from 'components/common/form/SimpleField';
import { useAppData } from 'hooks/useAppData';
import { useUrlParams } from 'hooks/useUrlParams';
import { languageSelector } from 'store/slices/language';
import { RootState } from 'store/store';
import { AUTH_CONSTANTS } from 'utils/constants';
import { emailFormat, required } from 'utils/validators';

export interface SignupFormFields {
    surname: string;
    name: string;
    email: string;
    password: string;
    cgu: boolean;
    isTester: boolean;
    language: string;
}

const Signup: FunctionComponent<InjectedFormProps<SignupFormFields>> = ({ error, handleSubmit, submitting, valid }) => {
    const { t } = useTranslation();
    const { language } = useSelector(languageSelector);
    const { AUTH_URL } = useAppData();
    const updateUrlParams = useUrlParams();

    return (
        <>
            <h1 className="title">{t('auth.signup.title')}</h1>
            <SsoBanner />
            <form className="base-margin" method="post" onSubmit={handleSubmit}>
                {error && <div className="error">{error}</div>}
                <div className="auth-form__name-fields">
                    <Field
                        className="margin-right-16"
                        component={SimpleField}
                        label={t('auth.surname.label')}
                        name="surname"
                        type="text"
                        validate={[required]}
                    />
                    <Field
                        component={SimpleField}
                        label={t('auth.name.label')}
                        name="name"
                        type="text"
                        validate={[required]}
                    />
                </div>
                <Field
                    component={SimpleField}
                    label={t('auth.email.label')}
                    name="email"
                    type="email"
                    validate={[emailFormat, required]}
                />
                <Field
                    component={SimpleField}
                    label={t('auth.password.label')}
                    name="password"
                    type="password"
                    validate={[required]}
                />
                <Field component={CheckboxField} name="cgu" validate={required}>
                    <>
                        {t('auth.cgu.label.firstPart')}
                        &nbsp;
                        <Link className="link" to={`/${language}/tos`}>
                            {t('cgu.link.title')}
                        </Link>
                        &nbsp;
                        {t('auth.cgu.label.secondPart')}
                        &nbsp;
                        <Link className="link" to={`/${language}/confidentiality`}>
                            {t('cgu.link.policy')}
                        </Link>
                        &nbsp;
                        {t('auth.cgu.label.thirdPart')}
                    </>
                </Field>
                <Field component={CheckboxField} name="isTester">
                    {t('auth.isTester.label')}
                </Field>
                <Button
                    classes="button--block button--black base-margin"
                    disabled={!valid}
                    submitting={submitting}
                    text={t('auth.signup.submit')}
                    submit
                />
                <a href={`${AUTH_URL}/api/auth/freya/google`}>
                    <Button
                        classes="button--block button--white base-margin"
                        img="/assets/icons/google.svg"
                        text={t('auth.google')}
                    />
                </a>
            </form>
            <div className="base-margin auth-form__redirectLink">
                <span>{t('auth.signup.alreadyHaveAnAccount')}</span>
                <button
                    className="link strong"
                    onClick={() => updateUrlParams({ open: AUTH_CONSTANTS.LOGIN })}
                    type="button"
                >
                    {t('auth.signup.switchToLogin')}
                </button>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState, props: { language: string }) => ({
    initialValues: { cgu: false, isTester: true, language: props.language },
});

export default connect(mapStateToProps)(
    reduxForm<SignupFormFields>({
        form: 'signupForm',
    })(Signup)
);
