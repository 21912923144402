import classnames from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DeleteAccountModal from 'components/DeleteAccountModal';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import useLogout from 'hooks/useLogout';
import { languageSelector } from 'store/slices/language';
import { isMenuOpenSelector, setMenuOpen } from 'store/slices/menu';
import { deleteAccount as deleteAccountAction } from 'store/slices/user';

import './style.scss';

const SecondaryMenu = () => {
    const { t } = useTranslation();
    const menuIsOpen = useAppSelector(isMenuOpenSelector);
    const logout = useLogout();
    const dispatch = useAppDispatch();
    const { API_URL } = useAppData();
    const { user } = useAppSelector((state) => state);
    const { language } = useAppSelector(languageSelector);
    const showError = () => {
        // eslint-disable-next-line no-alert
        window.alert(t('error.account.cannot_delete'));
    };

    const deleteAccount = async () => {
        const deletionIsSuccessful = await dispatch(deleteAccountAction(API_URL));
        if (deletionIsSuccessful) {
            logout();
        } else {
            showError();
        }
    };

    const onDeleteAccount = () => {
        confirmAlert({
            // eslint-disable-next-line react/no-unstable-nested-components
            customUI: ({ onClose }) => <DeleteAccountModal onClose={onClose} onConfirm={deleteAccount} />,
        });
    };

    return (
        <ul className={classnames('secondary-menu', { 'secondary-menu--close': !menuIsOpen })}>
            {menuIsOpen && user ? (
                <>
                    <li className="secondary-menu__link">
                        <button
                            onClick={() => {
                                dispatch(setMenuOpen(false));
                                logout();
                            }}
                            type="button"
                        >
                            {t('menu.logout')}
                        </button>
                    </li>
                    <li className="secondary-menu__link">
                        <button
                            onClick={() => {
                                dispatch(setMenuOpen(false));
                                onDeleteAccount();
                            }}
                            type="button"
                        >
                            {t('menu.deleteAccount')}
                        </button>
                    </li>
                </>
            ) : (
                <>
                    <li className="secondary-menu__link">
                        <Link
                            onClick={() => {
                                dispatch(setMenuOpen(false));
                            }}
                            title={t('menu.legalNotices')}
                            to={`/${language}/legalnotices`}
                        >
                            {t('menu.legalNotices')}
                        </Link>
                    </li>
                    <li className="secondary-menu__link">
                        <Link
                            onClick={() => {
                                dispatch(setMenuOpen(false));
                            }}
                            title={t('menu.termsOfService')}
                            to={`/${language}/tos`}
                        >
                            {t('menu.termsOfService')}
                        </Link>
                    </li>
                    <li className="secondary-menu__link">
                        <Link
                            onClick={() => {
                                dispatch(setMenuOpen(false));
                            }}
                            title={t('menu.confidentiality')}
                            to={`/${language}/confidentiality`}
                        >
                            {t('menu.confidentiality')}
                        </Link>
                    </li>
                </>
            )}
        </ul>
    );
};

export default SecondaryMenu;
