import { FunctionComponent } from 'react';
import './style.scss';

interface DiagramPartProps {
    className: string;
    progress: number;
    size: number;
}

const DiagramPart: FunctionComponent<DiagramPartProps> = ({ className = '', size = 100, progress }) => {
    const fillSize = `${(progress * size) / 100}px`;
    const conicSize = `${size}px`;

    return (
        <div className={`diagram-part ${className}`}>
            <div className="diagram-part__shape">
                <div className="diagram-part__full-circle" style={{ height: conicSize, width: conicSize }}>
                    <div className="diagram-part__fill" style={{ height: fillSize, width: fillSize }} />
                </div>
            </div>
        </div>
    );
};

export default DiagramPart;
