import { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from 'components/common/Button';
import Main from 'components/layout/Main';
import './style.scss';

const MainWithBackButton: FunctionComponent<{ children: ReactNode; title?: string }> = ({ children, title }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const onGoBack = () => history.goBack();

    return (
        <div className="main-container">
            <div className="main-back">
                <Button
                    classes="button--transparent main-back__button"
                    img="/assets/icons/back-arrow.svg"
                    onClick={onGoBack}
                    text={t('common.legalPage.back')}
                />
                <Main>
                    <h1 className="title main-back__title">{title}</h1>
                    <div className="main-back__content">{children}</div>
                </Main>
            </div>
        </div>
    );
};

export default MainWithBackButton;
