import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import langDe from 'translations/de.json';
import langEn from 'translations/en.json';
import langFr from 'translations/fr.json';

export const defaultLanguage = 'fr';

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: defaultLanguage,
        debug: process.env.NODE_ENV !== 'production',
        keySeparator: false,
        resources: {
            de: { translation: langDe },
            en: { translation: langEn },
            fr: { translation: langFr },
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        fallbackLng: defaultLanguage,
        returnNull: false,
    });

export default i18n;
