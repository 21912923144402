import ValueExplanation from 'components/pdf/common/ValueExplanation';

import './style.scss';

const PageValueExplanation2 = () => (
    <div className="pdf-value-explanation-page__container">
        <div className="pdf-value-explanation-page__content">
            <ValueExplanation dimensionKey="CONDITION" />
            <ValueExplanation dimensionKey="MISSION" />
            <ValueExplanation dimensionKey="PRESTIGE" />
        </div>
    </div>
);

export default PageValueExplanation2;
