import { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import RouteChangeTracker from 'components/RouteChangeTracker';
import { AppDataInterface, AppDataProvider } from 'hooks/useAppData';
import LanguageProvider from 'Language/LanguageProvider';
import Routes from 'Routes';
import store from 'store/store';
import 'App.scss';

interface AppProps {
    appData: AppDataInterface;
}

class App extends PureComponent<AppProps> {
    constructor(props: AppProps) {
        super(props);

        // Disable fast-tap zoom on mobile
        let lastTouchEnd = 0;
        document.addEventListener(
            'touchend',
            (event) => {
                const now = new Date().getTime();
                if (now - lastTouchEnd <= 300) {
                    event.preventDefault();
                }

                lastTouchEnd = now;
            },
            false
        );
    }

    render() {
        const { appData } = this.props;

        return (
            <AppDataProvider appData={appData}>
                <Provider store={store}>
                    <Router>
                        <RouteChangeTracker />
                        <LanguageProvider>
                            <Routes />
                        </LanguageProvider>
                    </Router>
                </Provider>
            </AppDataProvider>
        );
    }
}

export default App;
