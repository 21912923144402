import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from 'components/common/Button';
import LanguageSwitcher from 'components/common/LanguageSwitcher';
import HeaderMenu from 'components/home/HomeHeader/HeaderMenu';
import useWindowSize from 'hooks/useWindowSize';
import './style.scss';

const HomeHeader: FunctionComponent<{ openLogin: VoidFunction; openSignup: VoidFunction }> = ({
    openLogin,
    openSignup,
}) => {
    const { t } = useTranslation();
    const isMobile = 'xs' === useWindowSize().size;

    return (
        <header className="home-header" role="banner">
            <div className="home-header__header-menu__wrapper">
                <HeaderMenu
                    className="home-header__header-menu"
                    leftElement={
                        <Link to="/account">
                            <img alt="Logo" height="35" src="/assets/logo-white.svg" />
                        </Link>
                    }
                    rightElement={
                        <div className="home-header__header-menu--right">
                            <Button
                                classes="header-menu__login-button"
                                img="/assets/icons/person.svg"
                                onClick={() => openLogin()}
                                text={t('header_menu.login')}
                            />
                            {!isMobile && (
                                <>
                                    <div className="home-header__header-menu__pipe" />
                                    <LanguageSwitcher className="language-switcher__small" color="black" shortLabel />
                                </>
                            )}
                        </div>
                    }
                />
            </div>
            <div className="home-header__container">
                <div className="home-header__intro">
                    <h1 className="home-header__title">
                        <Trans i18nKey="home.header.title" />
                    </h1>
                    <h2 className="home-header__subtitle">{t('home.header.subtitle')}</h2>
                    <Button onClick={openSignup} text={t('home.start_button')} />
                </div>
                <div className="home-header__wrapper-illustration">
                    <img alt="header-illustration" className="home-header__illustration" src="/assets/freya-home.svg" />
                </div>
            </div>
        </header>
    );
};

export default HomeHeader;
