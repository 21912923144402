import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppData } from 'hooks/useAppData';
import './style.scss';

interface SimpleFieldProps {
    className?: string;
    input: {
        name: string;
    };
    label: string;
    labelHidden: string;
    meta: {
        error?: string;
        touched: boolean;
        warning?: string;
    };
    placeholder?: string;
    type: string;
}

const SimpleField: FunctionComponent<SimpleFieldProps> = ({
    className = '',
    input,
    label,
    labelHidden = false,
    placeholder,
    type,
    meta: { touched, error, warning },
    ...props
}) => {
    const { t } = useTranslation();

    const { MARCO_V3_URL, VASCO_V2_URL } = useAppData();
    let err = error;
    let warn = warning;

    let warnValues = {};
    if ('emailAlreadyUsed' === error) {
        err = undefined;
        warn = 'error.email.alreadyUsed';
        warnValues = { MARCO_V3_URL, VASCO_V2_URL };
    }

    return (
        <div
            className={`
                field
                ${touched && err ? 'field--error' : ''}
                ${touched && warn ? 'field--warning' : ''}
                ${className}
            `}
        >
            <label className={labelHidden ? 'field__label--hidden' : ''} htmlFor={input.name}>
                {label}
            </label>
            <input {...input} {...props} id={input.name} placeholder={placeholder} type={type} />
            {touched &&
                ((err && <span>{t(err)}</span>) ||
                    // eslint-disable-next-line react/no-danger
                    (warn && <span dangerouslySetInnerHTML={{ __html: t(warn, warnValues) }} />))}
        </div>
    );
};

export default SimpleField;
