import { useSelector } from 'react-redux';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import LoginForm, { LoginFormFields } from 'components/AuthFormsModal/LoginForm';
import LostPasswordForm, { LostPasswordFormFields } from 'components/AuthFormsModal/LostPasswordForm';
import SignupForm, { SignupFormFields } from 'components/AuthFormsModal/SignupForm';
import Modal from 'components/common/Modal';
import { useAppDispatch } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { getUrlParams, useLocalizedHistoryPush, useUrlParams } from 'hooks/useUrlParams';
import { login, signup } from 'jt-client/actions';
import { passwordLost } from 'jt-client/actions/user';
import { languageSelector } from 'store/slices/language';
import { AUTH_CONSTANTS } from 'utils/constants';
import './style.scss';

const AuthFormsModal = () => {
    const { FREYA_URL } = useAppData();
    const { open: openForm } = getUrlParams();
    const push = useLocalizedHistoryPush();
    const updateUrlParams = useUrlParams();
    const { language } = useSelector(languageSelector);
    const dispatch = useAppDispatch();

    const onPasswordLost: FormSubmitHandler<LostPasswordFormFields> = (values) =>
        dispatch(
            passwordLost({
                ...values,
                appName: 'FREYA',
                teamName: 'Freya',
                host: FREYA_URL,
            })
        );

    const onLogin: FormSubmitHandler<LoginFormFields> = (values) => dispatch(login(values));
    const onSignIn: FormSubmitHandler<SignupFormFields> = (values) => dispatch(signup(values));

    return (
        <Modal closeModal={() => updateUrlParams({ open: null })} fullScreen>
            <div className="auth-form">
                {AUTH_CONSTANTS.LOGIN === openForm && (
                    <LoginForm onSubmit={onLogin} onSubmitSuccess={() => push('/login-redirect')} />
                )}
                {AUTH_CONSTANTS.SIGNUP === openForm && (
                    <SignupForm
                        language={language!}
                        onSubmit={onSignIn}
                        onSubmitSuccess={() => push('/login-redirect')}
                    />
                )}
                {AUTH_CONSTANTS.LOSTPASSWORD === openForm && (
                    <LostPasswordForm
                        onSubmit={onPasswordLost}
                        onSubmitSuccess={() => updateUrlParams({ open: AUTH_CONSTANTS.LOGIN })}
                    />
                )}
            </div>
        </Modal>
    );
};

export default AuthFormsModal;
