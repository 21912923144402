import classnames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DiagramPart from 'components/Diagram/DiagramPart';
import { useAppData } from 'hooks/useAppData';
import { Get } from 'utils/http';
import './style.scss';

export interface DimensionResult {
    grade: number;
    dimension: string;
}

interface DiagramProps {
    isPdfDiagram?: boolean;
    isShareDiagram?: boolean;
    formId: string;
}

const computeDisplayableDimensionResults = (dimensionResults: { grade: number; dimension: string }[]) => {
    const maxValue = Math.max(...dimensionResults.map((result) => result.grade));
    if (maxValue < 1) {
        return dimensionResults.map((result) => ({
            ...result,
            grade: result.grade * 10,
        }));
    }

    return dimensionResults;
};

const Diagram: FunctionComponent<DiagramProps> = ({ isPdfDiagram = false, isShareDiagram = false, formId }) => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const [loading, setLoading] = useState<boolean>(true);
    const [dimensionResults, setDimensionResults] = useState<DimensionResult[]>([]);
    let size = 280;

    if (isPdfDiagram) {
        size = 210;
    }

    if (isShareDiagram) {
        size = 350;
    }

    useEffect(() => {
        (async () => {
            const { dimensionResults: dimResults } = await Get(`${API_URL}/diagram/${formId}`);
            setDimensionResults(dimResults);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return null;
    }

    const displayedDimensionResults = computeDisplayableDimensionResults(dimensionResults);

    return (
        <div className={classnames('analysis', { 'pdf-size': isPdfDiagram, 'share-size': isShareDiagram })}>
            <div className="diagram">
                <div className="diagram__center">
                    <img alt="JobTeaser" src="/assets/logo-picto.svg" />
                </div>
                {displayedDimensionResults.map((dimensionResult) => (
                    <DiagramPart
                        key={dimensionResult.dimension}
                        className={`diagram-part--${dimensionResult.dimension}`}
                        progress={Math.round(dimensionResult.grade * 10)}
                        size={size}
                    />
                ))}
            </div>
            {dimensionResults
                .sort((d1, d2) => d2.grade - d1.grade)
                .map((dimensionResult) => (
                    <div
                        key={dimensionResult.dimension}
                        className={`analysis__text analysis__text--${dimensionResult.dimension}
                            ${isPdfDiagram ? 'pdf-size' : ''}`}
                    >
                        <span>{`${Math.round(dimensionResult.grade * 10)}%`}</span>
                        {t(`diagram.${dimensionResult.dimension}.text`)}
                    </div>
                ))}
        </div>
    );
};

export default Diagram;
