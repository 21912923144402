import { Trans, useTranslation } from 'react-i18next';

import './style.scss';

const PageIntro = () => {
    const { t } = useTranslation();

    return (
        <div className="pdf-intro-page__container">
            <h2>{t('pdf.introPage.title')}</h2>
            <p>
                <Trans i18nKey="pdf.introPage.text1" />
            </p>
            <p>
                <Trans i18nKey="pdf.introPage.text2" />
            </p>
            <p>
                <Trans i18nKey="pdf.introPage.text3" />
            </p>
            <p>
                <Trans i18nKey="pdf.introPage.text4" />
            </p>
            <div className="pdf-intro-page__intro-shape-container">
                <img alt="Intro page shape" src="/assets/pdf-intro-shape.svg" />
            </div>
        </div>
    );
};

export default PageIntro;
