import { FunctionComponent, ReactNode } from 'react';
import './style.scss';

interface HeaderMenuProps {
    className?: string;
    leftElement: ReactNode;
    rightElement: ReactNode;
}

const HeaderMenu: FunctionComponent<HeaderMenuProps> = ({ className = '', leftElement, rightElement }) => (
    <div className={`header-menu__wrapper-menu ${className}`}>
        <div className="header-menu__inner">
            {leftElement}
            {rightElement}
        </div>
    </div>
);

export default HeaderMenu;
