import { useAppDispatch } from 'hooks/store';
import { setMenuOpen } from 'store/slices/menu';
import './style.scss';

const CloseButton = () => {
    const dispatch = useAppDispatch();

    return (
        <button className="menu__close-button" onClick={() => dispatch(setMenuOpen(false))} type="button">
            <img alt="close" src="/assets/icons/white-cross.svg" />
        </button>
    );
};

export default CloseButton;
