import { useTranslation } from 'react-i18next';
import Modal from 'components/common/Modal';

interface DeleteAccountModalProps {
    onClose: VoidFunction;
    onConfirm: VoidFunction;
}
const DeleteAccountModal = ({ onClose, onConfirm }: DeleteAccountModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal closeModal={onClose} title={t('deleteAccount.title')} fullScreen>
            <p>{t('deleteAccount.text')}</p>
            <p>{t('deleteAccount.text.previousTools')}</p>
            <button
                className="button button--block button--black base-margin"
                onClick={() => {
                    onClose();
                    onConfirm();
                }}
                type="button"
            >
                {t('deleteAccount.yes')}
            </button>
            <button className="button button--block button--grey base-margin" onClick={onClose} type="button">
                {t('deleteAccount.cancel')}
            </button>
        </Modal>
    );
};

export default DeleteAccountModal;
