import { FunctionComponent } from 'react';
import './style.scss';

interface HomeCardProps {
    image: string;
    text: string;
    title: string;
}

const HomeCard: FunctionComponent<HomeCardProps> = ({ image, text, title }) => (
    <div className="home-card">
        <img alt="icon" className="home-card__img" src={image} />
        <h3 className="home-card__title">{title}</h3>
        <p>{text}</p>
    </div>
);

export default HomeCard;
