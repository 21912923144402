import classnames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';
import Backdrop from 'components/common/Backdrop';
import './style.scss';

interface ModalProps {
    children: ReactNode;
    closeModal: VoidFunction;
    fullScreen?: boolean;
    title?: string | null;
}

const Modal: FunctionComponent<ModalProps> = ({ children, closeModal, fullScreen = false, title }) => (
    <Backdrop>
        <div className={classnames('modal', { 'modal--fullscreen': fullScreen })}>
            <div className="modal__inner">
                <button onClick={closeModal} type="button">
                    <img alt="close" className="modal__close" src="/assets/icons/close.svg" />
                </button>
                {title && <h1 className="modal__title">{title}</h1>}
                {children}
            </div>
        </div>
    </Backdrop>
);

export default Modal;
