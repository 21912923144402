import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { SubmissionError } from 'redux-form';
import { RootState } from '../store';
import { completeFirstStep, completeSecondStep } from 'store/slices/progress';
import { Get } from 'utils/http';

export type GradeEquality = {
    order: number;
    equalityPreferences: {
        preference: number | null;
        question: {
            key: string;
        };
    }[];
};
type GradeEqualitiesState = GradeEquality[];

const initialState = [] as GradeEqualitiesState;

export const gradeEqualitiesSlice = createSlice({
    name: 'gradeEqualities',
    initialState,
    reducers: {
        cleanGradeEqualities: () => [],
        setGradeEqualities: (state, { payload }: PayloadAction<GradeEqualitiesState>) => payload,
    },
});

export const recoverGradeEqualities =
    (API_URL: string): ThunkAction<void, RootState, void, AnyAction> =>
    async (dispatch, getState) => {
        try {
            const { currentFormId } = getState();

            const gradeEqualities = await Get(`${API_URL}/grade/${currentFormId}/equalities`);
            dispatch(completeFirstStep());
            if (!gradeEqualities.length) {
                dispatch(completeSecondStep());
            }
            dispatch(gradeEqualitiesSlice.actions.setGradeEqualities(gradeEqualities));
        } catch (e) {
            throw new SubmissionError({ _error: 'error.server.unknown' });
        }
    };

export const { cleanGradeEqualities } = gradeEqualitiesSlice.actions;

export default gradeEqualitiesSlice.reducer;
