import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from './store';
import { languageSelector } from 'store/slices/language';

export const useLocalizedHistoryPush = () => {
    const { language } = useAppSelector(languageSelector);
    const history = useHistory();

    return (url: string) => {
        const baseRoute = `/${language}`;
        const route = url && url !== '/' ? `${baseRoute}${url}` : baseRoute;
        history.push(route);
    };
};

export const getUrlWithoutLanguage = (language: string, url: string) => {
    const re = new RegExp(`^(/${language}/)`);

    return url.replace(re, '');
};

export const getUrlLanguage = (url: string) => {
    const prefix = url.match(/^(\/[a-z]{2}\/)|^\/[a-z]{2}$/g);
    if (prefix) {
        return prefix[0].substring(1, 3);
    }

    return undefined;
};

export const useUrlParams = () => {
    const location = useLocation();
    const history = useHistory();

    return (urlParams: Record<string, string | null>) => {
        const currentSearchParams = qs.parse(location.search);
        const newSearchParams = {
            ...currentSearchParams,
            ...urlParams,
        };

        Object.keys(newSearchParams).forEach((key) => {
            if (newSearchParams[key] === null || newSearchParams[key] === undefined) {
                delete newSearchParams[key];
            }
        });
        const search = `?${qs.stringify(newSearchParams)}`;
        history.push({ search });
    };
};

export const getUrlParams = () => {
    const { search } = useLocation();

    return qs.parse(search);
};
