import { AnyAction, createSlice, PayloadAction, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { SubmissionError } from 'redux-form';
import { RootState } from '../store';
import { cleanGradeEqualities } from 'store/slices/gradeEqualities';
import { completeFirstStep, completeSecondStep, resetProgress, setOrderProgress } from 'store/slices/progress';
import { Reply, setReplies } from 'store/slices/replies';
import { Get, Post } from 'utils/http';

type Form = {
    id: string;
    firstStepComplete: boolean;
    secondStepComplete: boolean;
    orderProgress: number;
    replies: Reply[];
};

type CurrentFormState = string | null;

const initialState = null as CurrentFormState;

export const currentFormIdSlice = createSlice({
    name: 'currentFormId',
    initialState,
    reducers: {
        setFormId: (state, action: PayloadAction<string>) => action.payload,
        cleanForm: () => null,
    },
});

const storeForm = (form: Form, dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    dispatch(setReplies(form.replies));
    dispatch(resetProgress());
    dispatch(cleanGradeEqualities);
    if (form.firstStepComplete) {
        dispatch(completeFirstStep());
    }
    if (form.secondStepComplete) {
        dispatch(completeSecondStep());
    }
    dispatch(setOrderProgress(form.orderProgress));
    dispatch(currentFormIdSlice.actions.setFormId(form.id));
};

export const createForm =
    (API_URL: string): ThunkAction<void, RootState, void, AnyAction> =>
    async (dispatch) => {
        try {
            const newForm = await Post(`${API_URL}/form/`);
            storeForm(newForm, dispatch);
        } catch (e) {
            throw new SubmissionError({ _error: 'error.server.unknown' });
        }
    };

export const recoverForm =
    (API_URL: string): ThunkAction<void, RootState, void, AnyAction> =>
    async (dispatch) => {
        try {
            const lastForm = await Get(`${API_URL}/form/last_form`);
            if (lastForm) {
                storeForm(lastForm, dispatch);
            }
        } catch (e) {
            throw new SubmissionError({ _error: 'error.server.unknown' });
        }
    };

export const { cleanForm } = currentFormIdSlice.actions;

export default currentFormIdSlice.reducer;
