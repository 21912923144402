import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import SimpleField from 'components/common/form/SimpleField';
import { required } from 'utils/validators';

const PasswordResetForm: FunctionComponent<InjectedFormProps<{ password: string }>> = ({
    error,
    handleSubmit,
    submitting,
    valid,
}) => {
    const { t } = useTranslation();

    return (
        <form className="base-margin" method="post" onSubmit={handleSubmit}>
            {error && <div className="errors">{error}</div>}
            <Field
                component={SimpleField}
                label={t('auth.newPassword.label')}
                name="password"
                placeholder={t('auth.newPassword.label')}
                type="password"
                validate={[required]}
                labelHidden
            />
            <Field
                component={SimpleField}
                label={t('auth.confirmationPassword.label')}
                name="confirmationPassword"
                placeholder={t('auth.confirmationPassword.label')}
                type="password"
                validate={[required]}
                labelHidden
            />
            <button
                className="button button--block button--blue base-margin"
                disabled={!valid || submitting}
                type="submit"
            >
                {t('auth.resetPassword.submit')}
            </button>
        </form>
    );
};

export default reduxForm<{ password: string }>({
    form: 'PasswordResetForm',
})(PasswordResetForm);
