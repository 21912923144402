export enum SocialNetworks {
    LinkedIn = 'Linkedin',
    Facebook = 'Facebook',
}

const socialNetworkConfig = {
    [SocialNetworks.LinkedIn]: {
        networkUrl: 'https://www.linkedin.com/shareArticle?mini=true&url=',
    },
    [SocialNetworks.Facebook]: {
        networkUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
    },
};

export const shareOnSocialNetwork = (network: SocialNetworks, link: string) => {
    const { networkUrl } = socialNetworkConfig[network];
    const url = `${networkUrl}${encodeURI(link)}`;
    window.open(url, 'share', '_blank, width=700, height=700, resizable=yes, scrollbars=yes, left=50px, top=50px');

    return false;
};
