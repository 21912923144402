import { FunctionComponent, ReactElement, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Backdrop from 'components/common/Backdrop';
import CloseButton from 'components/layout/CloseButton';
import Menu from 'components/layout/Menu';
import MenuButton from 'components/layout/MenuButton';
import SecondaryMenu from 'components/layout/SecondaryMenu';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import useWindowSize from 'hooks/useWindowSize';
import { checkToken } from 'jt-client/actions';
import { isMenuOpenSelector, setMenuOpen } from 'store/slices/menu';
import { getUser } from 'store/slices/user';

import './style.scss';

const ConditionalWrapper: FunctionComponent<{
    condition: boolean;
    wrapper: (child: ReactElement) => ReactElement;
    children: ReactElement;
}> = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const CustomBackdrop = (child: ReactNode) => {
    const dispatch = useAppDispatch();

    return (
        <Backdrop
            onClick={(event) => {
                if (event.target === event.currentTarget) {
                    // only dispatch if the backdrop is clicked
                    // prevent dispatch from being emit due to event bubbling eg. on language switcher click
                    dispatch(setMenuOpen(false));
                }
            }}
            zIndex={499}
        >
            {child}
        </Backdrop>
    );
};

const Panel: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
    const menuIsOpen = useAppSelector(isMenuOpenSelector);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const isMobile = 'xs' === useWindowSize().size;
    const { user } = useAppSelector((state) => state);

    useEffect(() => {
        async function fetchToken() {
            const result = await dispatch(checkToken());
            if (result?.success) {
                await dispatch(getUser(API_URL));
            }
        }
        if (!user) {
            fetchToken();
        }
    }, []);

    return (
        <div className="container__panel">
            <ConditionalWrapper condition={menuIsOpen} wrapper={CustomBackdrop}>
                <div className={`panel ${menuIsOpen ? 'panel--open' : ''}`}>
                    <header className="panel__header">
                        <Link title={t('general.account')} to="/account">
                            <img alt="Menu" src="/assets/logo_freya_white.svg" width="107" />
                        </Link>
                        {menuIsOpen ? <CloseButton /> : <MenuButton />}
                    </header>
                    {!isMobile && !menuIsOpen && <div className="panel__inner">{children}</div>}
                    {menuIsOpen && <Menu />}
                    {(!isMobile || menuIsOpen) && <SecondaryMenu />}
                </div>
            </ConditionalWrapper>
        </div>
    );
};

export default Panel;
