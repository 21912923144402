import dayjs from 'dayjs';
import queryString from 'query-string';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { DimensionResult } from 'components/Diagram';
import BackPage from 'components/pdf/BackPage';
import FrontPage from 'components/pdf/FrontPage';
import PageContentPresentation from 'components/pdf/PageContentPresentation';
import PageDimensionHierarchy from 'components/pdf/PageDimensionHierarchy';
import PageIntro from 'components/pdf/PageIntro';
import PageLayout from 'components/pdf/PageLayout';
import PageResults from 'components/pdf/PageResults';
import PageSubResults from 'components/pdf/PageSubResults';
import { SubDimensionResult } from 'components/pdf/PageSubResults/SubResult';
import PageSummary from 'components/pdf/PageSummary';
import PageValueExplanation1 from 'components/pdf/PageValueExplanation1';
import PageValueExplanation2 from 'components/pdf/PageValueExplanation2';
import { useAppData } from 'hooks/useAppData';
import { UserStateDefined } from 'store/slices/user';
import { Get } from 'utils/http';
import './style.scss';

interface Form {
    subDimensionResults: SubDimensionResult[];
    dimensionResults: DimensionResult[];
    user: UserStateDefined;
}

const Restitution: FunctionComponent = () => {
    const { search } = useLocation();
    const { formId } = useParams<{ formId: string }>();
    const { API_URL } = useAppData();
    const [loading, setLoading] = useState(true);
    const { security } = queryString.parse(search);
    const [form, setForm] = useState<Form | undefined>(undefined);
    const [dominantDimension, setDominantDimension] = useState<string | undefined>(undefined);

    const date = dayjs().format('DD/MM/YYYY');

    const sort = (a: SubDimensionResult, b: SubDimensionResult) => a.order - b.order;

    useEffect(() => {
        (async () => {
            const response: Form = await Get(`${API_URL}/pdf/data/${formId}?security=${security}`);

            const maxGrade = Math.max(...response.dimensionResults.map((d) => d.grade));
            const dimensionResult = response.dimensionResults.find((d) => d.grade === maxGrade)!;
            setDominantDimension(dimensionResult.dimension);

            response.subDimensionResults = response.subDimensionResults.sort(sort);

            setForm(response);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return null;
    }

    return (
        <div className="pdfLayout">
            <FrontPage date={date} user={form!.user} />
            <PageLayout createdAt={date} page={2} user={form!.user}>
                <PageIntro />
            </PageLayout>
            <PageLayout createdAt={date} page={3} user={form!.user}>
                <PageContentPresentation />
            </PageLayout>
            <PageLayout createdAt={date} page={4} user={form!.user}>
                <PageResults dimensionKey={dominantDimension!} formId={formId} />
            </PageLayout>
            <PageLayout createdAt={date} page={5} user={form!.user}>
                <PageDimensionHierarchy dimensionResults={form!.dimensionResults} />
            </PageLayout>
            <PageLayout createdAt={date} page={6} user={form!.user}>
                <PageSubResults startingNumber={1} subDimensionResults={form!.subDimensionResults.slice(0, 7)} />
            </PageLayout>
            <PageLayout createdAt={date} page={7} user={form!.user}>
                <PageSubResults startingNumber={8} subDimensionResults={form!.subDimensionResults.slice(7, 15)} />
            </PageLayout>
            <PageLayout createdAt={date} page={8} user={form!.user}>
                <PageSubResults
                    startingNumber={16}
                    subDimensionResults={form!.subDimensionResults.slice(15, 18)}
                    withShape
                />
            </PageLayout>
            <PageLayout createdAt={date} page={9} user={form!.user}>
                <PageSummary
                    dimensionKey={dominantDimension!}
                    subDimensionKey1={form!.subDimensionResults[0].subDimension.key}
                    subDimensionKey2={form!.subDimensionResults[1].subDimension.key}
                    subDimensionKey3={form!.subDimensionResults[2].subDimension.key}
                />
            </PageLayout>
            <PageLayout createdAt={date} page={10} user={form!.user}>
                <PageValueExplanation1 />
            </PageLayout>
            <PageLayout createdAt={date} page={11} user={form!.user}>
                <PageValueExplanation2 />
            </PageLayout>
            <PageLayout createdAt={date} page={12} user={form!.user} backPage>
                <BackPage />
            </PageLayout>
        </div>
    );
};

export default Restitution;
