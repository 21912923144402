import { ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { recoverGradeEqualities } from 'store/slices/gradeEqualities';

const WithGradeEqualities = ({ children }: { children: ReactElement }) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const items = useAppSelector(({ gradeEqualities }) => gradeEqualities);
    const { API_URL } = useAppData();
    const push = useLocalizedHistoryPush();

    useEffect(() => {
        (async () => {
            if (!items.length) {
                try {
                    await dispatch(recoverGradeEqualities(API_URL));
                } catch (e) {
                    push('/account');
                }
            }

            setLoading(false);
        })();
    }, []);

    if (loading) {
        return null;
    }

    return children;
};

export default WithGradeEqualities;
