import { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAppSelector } from '../hooks/store';
import Diagram from 'components/Diagram';
import Account from 'routes/Account';
import Admin from 'routes/Admin';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import Final from 'routes/Final';
import Form from 'routes/Form';
import Home from 'routes/Home';
import Interstep from 'routes/Interstep';
import Confidentiality from 'routes/Legal/Confidentiality';
import LegalNotices from 'routes/Legal/LegalNotices';
import TermOfServices from 'routes/Legal/TermOfServices';
import LoginRedirect from 'routes/LoginRedirect';
import NotFound from 'routes/NotFound';
import PasswordReset from 'routes/PasswordReset';
import Pdf from 'routes/Pdf';
import Results from 'routes/Results';
import StepOne from 'routes/StepOne';
import StepTwo from 'routes/StepTwo';
import { languageSelector } from 'store/slices/language';

const LocalizedRoutes = () => {
    const { language } = useAppSelector(languageSelector);
    const Routes = useMemo(
        () => (
            <Switch>
                <Route component={Home} path={`/${language}`} exact />
                <Route component={LoginRedirect} path={`/${language}/login-redirect`} exact />
                <Route component={PasswordReset} path={`/${language}/password-reset/:token`} exact />
                <Route component={LegalNotices} path={`/${language}/legalnotices`} exact />
                <Route component={Confidentiality} path={`/${language}/confidentiality`} exact />
                <Route component={TermOfServices} path={`/${language}/tos`} exact />
                <Route component={Pdf} path={`/${language}/pdf/:formId`} exact />
                <Route
                    path={`/${language}/diagram/:formId`}
                    render={(props) => <Diagram formId={props.match.params.formId} isShareDiagram {...props} />}
                    exact
                />
                <AuthenticatedRoute component={Account} path={`/${language}/account`} exact />
                <AuthenticatedRoute component={Admin} path={`/${language}/admin`} exact />
                <AuthenticatedRoute component={Interstep} path={`/${language}/interstep`} exact />
                <AuthenticatedRoute component={StepOne} path={`/${language}/step/first/:order(\\d+)`} exact />
                <AuthenticatedRoute component={StepTwo} path={`/${language}/step/second/:order(\\d+)`} exact />
                <AuthenticatedRoute component={Final} path={`/${language}/final`} exact />
                <AuthenticatedRoute component={Form} path={`/${language}/form`} exact />
                <AuthenticatedRoute component={Results} path={`/${language}/results`} exact />
                <Route path={`/${language}/last-results`} exact>
                    <Redirect to={`/${language}/results`} />
                </Route>
                <Route component={NotFound} path={`/${language}/*`} exact />
            </Switch>
        ),
        [language]
    );

    return Routes;
};

export default LocalizedRoutes;
