import { useTranslation } from 'react-i18next';
import './style.scss';

const DescriptionLegalPages = () => {
    const { t } = useTranslation();

    return (
        <div className="description-legal-pages">
            <h3 className="title title--small">{t('common.legalPage.menu.title')}</h3>
            <p>{t('common.legalPage.menu.description')}</p>
            <img alt="freya" src="/assets/freya_illustration.svg" />
        </div>
    );
};

export default DescriptionLegalPages;
