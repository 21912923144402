import { useTranslation } from 'react-i18next';
import ValueExplanation from 'components/pdf/common/ValueExplanation';

import './style.scss';

const PageValueExplanation1 = () => {
    const { t } = useTranslation();

    return (
        <div className="pdf-value-explanation-page__container">
            <div className="pdf-value-explanation-page__heading">
                <div>
                    <h2>{t('pdf.valueExplanation.title')}</h2>
                    <h3>{t('pdf.valueExplanation.subtitle')}</h3>
                    <p>{t('pdf.valueExplanation.text')}</p>
                </div>
            </div>
            <div className="pdf-value-explanation-page__content">
                <ValueExplanation dimensionKey="SOCIETAL" />
                <ValueExplanation dimensionKey="SOCIAL" />
            </div>
        </div>
    );
};

export default PageValueExplanation1;
