import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { setOrderProgress } from 'store/slices/progress';
import { Post } from 'utils/http';

type EqualityOrderingState = {
    orderedQuestions: string[];
    questionToOrder: string | null;
    questionToCompare: string | null;
    orderToCompare: number;
    remainingQuestions: string[];
};

const initialState: EqualityOrderingState = {
    orderedQuestions: [],
    questionToOrder: null,
    questionToCompare: null,
    orderToCompare: 1,
    remainingQuestions: [],
};

export const equalityOrderingSlice = createSlice({
    name: 'equalityOrdering',
    initialState,
    reducers: {
        changeComparison: (state, { payload }: PayloadAction<number>) => {
            state.orderToCompare = payload;
            state.questionToCompare = state.orderedQuestions[payload - 1];
        },
        changeQuestionToOrder: (state) => {
            const [head, ...tail] = state.remainingQuestions;

            return {
                ...state,
                questionToOrder: head,
                questionToCompare: state.orderedQuestions[0],
                orderToCompare: 1,
                remainingQuestions: tail,
            };
        },
        startEqualityOrdering: (state, { payload }: PayloadAction<EqualityOrderingState['orderedQuestions']>) => {
            if (payload.length >= 2) {
                const [head, questionToOrder, ...tail] = payload;

                return {
                    orderedQuestions: [head],
                    questionToOrder,
                    questionToCompare: head,
                    orderToCompare: 1,
                    remainingQuestions: tail,
                };
            }

            return state;
        },
        setQuestionOrder: (state, { payload }: PayloadAction<number>) => {
            const { orderedQuestions, questionToOrder } = state;
            if (questionToOrder) {
                orderedQuestions.splice(payload - 1, 0, questionToOrder);

                state.orderedQuestions = orderedQuestions;
            }
        },
    },
});

export const startEqualityOrdering =
    (currentOrder: number): ThunkAction<void, RootState, void, AnyAction> =>
    (dispatch, getState) => {
        const { gradeEqualities } = getState();
        const gradeEquality = gradeEqualities.find(({ order }) => currentOrder === order);
        if (gradeEquality) {
            const questions = gradeEquality.equalityPreferences.map(({ question: { key } }) => key);
            dispatch(equalityOrderingSlice.actions.startEqualityOrdering(questions));
        }
    };

/**
 * Action dispatched when the user chooses a response between the two cards in the 2nd exercise.
 */
export const answerComparison =
    (isBetter: boolean, callback: VoidFunction): ThunkAction<void, RootState, void, AnyAction> =>
    (dispatch, getState) => {
        const { equalityOrdering } = getState();
        const { orderedQuestions, remainingQuestions } = equalityOrdering;
        let { orderToCompare: order } = equalityOrdering;
        let goNext;
        if (isBetter) {
            dispatch(equalityOrderingSlice.actions.setQuestionOrder(order));
            goNext = true;
        } else {
            order += 1;

            if (order > orderedQuestions.length) {
                dispatch(equalityOrderingSlice.actions.setQuestionOrder(order));
                goNext = true;
            } else {
                dispatch(equalityOrderingSlice.actions.changeComparison(order));
            }
        }

        if (goNext) {
            if (remainingQuestions.length) {
                dispatch(equalityOrderingSlice.actions.changeQuestionToOrder());
            } else {
                callback();
            }
        }
    };

export const postComparison =
    (order: number, API_URL: string): ThunkAction<void, RootState, void, AnyAction> =>
    async (dispatch, getState) => {
        const {
            currentFormId,
            equalityOrdering: { orderedQuestions },
            gradeEqualities,
            progress: { orderProgress, secondStepComplete },
        } = getState();

        await Post(`${API_URL}/grade/${currentFormId}/preference/${order}`, { orderedQuestions });

        if (orderProgress === order && orderProgress < gradeEqualities.length && !secondStepComplete) {
            dispatch(setOrderProgress(order + 1));
        }
    };

export default equalityOrderingSlice.reducer;
