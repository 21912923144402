import { FunctionComponent } from 'react';
import Spinner from '../Spinner';
import './style.scss';

interface ButtonProps {
    classes?: string;
    disabled?: boolean;
    img?: string;
    onClick?: (args: any) => void;
    submit?: boolean;
    submitting?: boolean;
    text?: string | null;
}

const Button: FunctionComponent<ButtonProps> = ({
    classes = '',
    onClick,
    disabled = false,
    img = '',
    submit = false,
    submitting = false,
    text,
}) => (
    <button
        className={`button ${classes}`}
        disabled={disabled || submitting}
        onClick={onClick}
        type={submit ? 'submit' : 'button'}
    >
        {submitting ? (
            <Spinner />
        ) : (
            <>
                {img && <img alt="" src={img} />}
                {text}
            </>
        )}
    </button>
);

export default Button;
