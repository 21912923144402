import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import PasswordResetForm from 'components/PasswordResetForm';
import { useAppDispatch } from 'hooks/store';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { updatePassword } from 'jt-client/actions/user';
import './style.scss';

const PasswordReset = () => {
    const push = useLocalizedHistoryPush();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { token } = useParams<{ token: string }>();

    const onSubmit: FormSubmitHandler<{ password: string }> = ({ password }) =>
        dispatch(updatePassword(password, token));

    return (
        <div className="resetPassword">
            <h1 className="title">{t('auth.resetPassword.title')}</h1>
            <PasswordResetForm onSubmit={onSubmit} onSubmitSuccess={() => push('/login-redirect')} />
        </div>
    );
};

export default PasswordReset;
