import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface DescriptionExerciseProps {
    hiddenDescription?: boolean;
    step: 'step1' | 'step2';
    type?: 'long' | 'short';
}

const DescriptionExercise: FunctionComponent<DescriptionExerciseProps> = ({
    step,
    type = 'long',
    hiddenDescription = false,
}) => {
    const { t } = useTranslation();

    const text1 = t(`${step}.${type}Description.text1`);
    const text2 = t(`${step}.${type}Description.text2`);

    return (
        <div className={`descriptionExercise descriptionExercise__${type}`}>
            <h3>{t(`${step}.${type}Description.title`)}</h3>
            {!hiddenDescription && (
                <>
                    <p
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{ __html: text1 }}
                    />
                    <p
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{ __html: text2 }}
                    />
                </>
            )}
        </div>
    );
};

export default DescriptionExercise;
