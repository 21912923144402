import { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface CheckboxFieldProps {
    children: ReactNode;
    className?: string;
    input: {
        name?: string;
        onChange: VoidFunction;
        value?: string | boolean;
    };
    meta: {
        error?: string;
        touched: boolean;
        warning?: string;
    };
}

const CheckboxField: FunctionComponent<CheckboxFieldProps> = ({
    children,
    className = '',
    input: { value, name, ...otherInputProps },
    meta: { touched, error, warning },
}) => {
    const { t } = useTranslation();

    return (
        <div
            className={`
                checkbox
                ${touched && error ? 'checkbox--error' : ''}
                ${touched && warning ? 'checkbox--warning' : ''}
                ${className}
            `}
        >
            <input checked={!!value} id={name} {...otherInputProps} type="checkbox" />
            <div>
                {/* eslint-disable-next-line */}
                <label htmlFor={name}>{children}</label>
                {touched && ((error && <p>{t(error)}</p>) || (warning && <p>{t(warning)}</p>))}
            </div>
        </div>
    );
};

export default CheckboxField;
