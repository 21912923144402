import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';
import App from './App';

const container = document.getElementById('app')!;

const appData = JSON.parse(container.dataset.app!);

const sentryData = {
    dsn: appData.SENTRY_DSN_APP,
    environment: appData.SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
};

Sentry.init(sentryData);

const renderApp = () => {
    try {
        let appElement = <App appData={appData} />;

        if (process.env.NODE_ENV !== 'development') {
            appElement = (
                <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>
                    {appElement}
                </Sentry.ErrorBoundary>
            );
        }
        const root = createRoot(container);
        root.render(appElement);
    } catch (error) {
        console.error(error);
        if ('development' === process.env.NODE_ENV) {
            throw error;
        }
    }
};

renderApp();
