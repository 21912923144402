import { useTranslation } from 'react-i18next';
import Container from 'components/layout/Container';
import MainWithBackButton from 'components/layout/MainWithBackButton';
import Panel from 'components/layout/Panel';
import DescriptionLegalPages from 'components/StepDescription/DescriptionLegalPages';

const Confidentiality = () => {
    const { t } = useTranslation();

    return (
        <Container bgColor="white">
            <Panel>
                <DescriptionLegalPages />
            </Panel>
            <MainWithBackButton title={t('common.confidentiality.title')}>
                <div
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: t('common.confidentiality.content') }}
                    className="static-page"
                />
            </MainWithBackButton>
        </Container>
    );
};

export default Confidentiality;
