import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import LocalizedRoutes from 'Language/LocalizedRoutes';
import RedirectToLocalizedRoute from 'Language/RedirectToLocalizedRoute';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import MissingLanguage from 'routes/MissingLanguage';
import { languageSelector } from 'store/slices/language';

const Routes = () => {
    const { allowedLanguages } = useSelector(languageSelector);

    return (
        <Switch>
            <AuthenticatedRoute component={MissingLanguage} path="/missing-language" exact />
            <Route path={`/:lang(${allowedLanguages.join('|')})`}>
                <LocalizedRoutes />
            </Route>
            <Route path="*">
                <RedirectToLocalizedRoute />
            </Route>
        </Switch>
    );
};

export default Routes;
