import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProgressStateDefined {
    orderProgress: number;
    firstStepComplete: boolean;
    secondStepComplete: boolean;
}

type ProgressState = Partial<ProgressStateDefined>;

const initialState = {} as ProgressState;

export const progressSlice = createSlice({
    name: 'progress',
    initialState,
    reducers: {
        setOrderProgress: (state, action: PayloadAction<ProgressStateDefined['orderProgress']>) => {
            state.orderProgress = action.payload;
        },
        completeFirstStep: (state) => {
            state.firstStepComplete = true;
        },
        completeSecondStep: (state) => {
            state.secondStepComplete = true;
        },
        resetProgress: () => ({ orderProgress: 1 }),
    },
});

export const { completeFirstStep, completeSecondStep, resetProgress, setOrderProgress } = progressSlice.actions;

export default progressSlice.reducer;
