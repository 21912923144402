import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import Navigation from 'components/common/Navigation';
import ExerciseTwo from 'components/ExerciseTwo';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import MainWithSteps from 'components/layout/MainWithSteps';
import Panel from 'components/layout/Panel';
import PanelDescription from 'components/StepDescription/PanelDescription';
import { useAppSelector } from 'hooks/store';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import WithCurrentForm from 'providers/WithCurrentForm';
import WithGradeEqualities from 'providers/WithGradeEqualities';

const StepTwo = () => {
    const { t } = useTranslation();
    const push = useLocalizedHistoryPush();
    const { order } = useParams<{ order: string }>();
    const { orderProgress, secondStepComplete } = useAppSelector(({ progress }) => progress);
    const gradeEqualities = useAppSelector(({ gradeEqualities: ge }) => ge);
    const total = gradeEqualities.flatMap(({ equalityPreferences }) => equalityPreferences).length;
    const current = gradeEqualities
        .filter(({ order: o }) => o < parseInt(order, 10))
        .flatMap(({ equalityPreferences }) => equalityPreferences).length;

    if (secondStepComplete) {
        return <Redirect to="/final" />;
    }

    if (orderProgress && parseInt(order, 10) > orderProgress && !secondStepComplete) {
        return <Redirect to={`/step/second/${orderProgress}`} />;
    }

    const onBack = () => {
        push(`/step/second/${parseInt(order, 10) - 1}`);
    };

    return (
        <Container>
            <Panel>
                <PanelDescription step="step2" />
            </Panel>
            <Main>
                <MainWithSteps textContext={t('step2.title.text1')} title={t('step2.title.text2')}>
                    <ExerciseTwo order={parseInt(order, 10)} />
                    <Navigation
                        current={current}
                        disableBack={parseInt(order, 10) === 1}
                        onBack={onBack}
                        total={total}
                        displayBar
                    />
                </MainWithSteps>
            </Main>
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm>
        <WithGradeEqualities>
            <StepTwo {...props} />
        </WithGradeEqualities>
    </WithCurrentForm>
);
