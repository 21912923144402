import { FunctionComponent, ReactNode } from 'react';
import './style.scss';

interface ContainerProps {
    bgColor?: string;
    children: ReactNode;
}

const Container: FunctionComponent<ContainerProps> = ({ bgColor = 'pink', children }) => (
    <div className={`container container--${bgColor}`}>{children}</div>
);

export default Container;
