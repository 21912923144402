import { useEffect } from 'react';
import AuthFormsModal from 'components/AuthFormsModal';
import HomeContent from 'components/home/HomeContent';
import HomeHeader from 'components/home/HomeHeader';
import Footer from 'components/layout/Footer';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { getUrlParams, useLocalizedHistoryPush, useUrlParams } from 'hooks/useUrlParams';
import { checkToken, currentUser } from 'jt-client/actions';
import { AUTH_CONSTANTS } from 'utils/constants';
import './style.scss';

const Home = () => {
    const dispatch = useAppDispatch();
    const updateUrlParams = useUrlParams();
    const push = useLocalizedHistoryPush();
    const { open: openForm } = getUrlParams();
    const { id } = useAppSelector(({ auth }) => auth);
    const { isWebview } = useAppData();

    useEffect(() => {
        (async () => {
            const result = await dispatch(checkToken());
            if (result && result.success) {
                await dispatch(currentUser());
            }
        })();
    }, []);

    const openSignupForm = () => {
        if (id) {
            push('/login-redirect');
        } else {
            updateUrlParams({ open: AUTH_CONSTANTS.SIGNUP });
        }
    };

    const openLoginForm = () => {
        if (id) {
            push('/login-redirect');
        } else {
            document.body.setAttribute('class', 'body__noScroll');
            updateUrlParams({ open: AUTH_CONSTANTS.LOGIN });
        }
    };

    return (
        <div className="home">
            {openForm && <AuthFormsModal />}
            <HomeHeader openLogin={openLoginForm} openSignup={openSignupForm} />
            <HomeContent onClick={openSignupForm} />
            {!isWebview && <Footer />}
        </div>
    );
};

export default Home;
