import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import SsoBanner from 'components/AuthFormsModal/SsoBanner';
import Button from 'components/common/Button';
import SimpleField from 'components/common/form/SimpleField';
import { useAppData } from 'hooks/useAppData';
import { useUrlParams } from 'hooks/useUrlParams';
import { AUTH_CONSTANTS } from 'utils/constants';
import { emailFormat, required } from 'utils/validators';

export interface LoginFormFields {
    email: string;
    password: string;
}

const LoginForm: FunctionComponent<InjectedFormProps<LoginFormFields>> = ({
    error,
    handleSubmit,
    submitting,
    valid,
}) => {
    const { t } = useTranslation();
    const { AUTH_URL } = useAppData();
    const updateUrlParams = useUrlParams();

    return (
        <>
            <h1 className="title">{t('auth.login.title')}</h1>
            <SsoBanner />
            <form className="base-margin" method="post" onSubmit={handleSubmit}>
                {error && <div className="error">{error}</div>}
                <Field
                    component={SimpleField}
                    label={t('auth.email.label')}
                    name="email"
                    type="email"
                    validate={[required, emailFormat]}
                />
                <Field
                    component={SimpleField}
                    label={t('auth.password.label')}
                    name="password"
                    type="password"
                    validate={[required]}
                />
                <button
                    className="link strong"
                    onClick={() => updateUrlParams({ open: AUTH_CONSTANTS.LOSTPASSWORD })}
                    type="button"
                >
                    {t('auth.login.forgotten_password')}
                </button>
                <div>
                    <Button
                        classes="button--block button--black base-margin"
                        disabled={!valid}
                        submitting={submitting}
                        text={t('auth.login.submit')}
                        submit
                    />
                    <a href={`${AUTH_URL}/api/auth/freya/google`}>
                        <Button
                            classes="button--block button--white base-margin"
                            img="/assets/icons/google.svg"
                            text={t('auth.google')}
                        />
                    </a>
                </div>
                <div className="base-margin auth-form__redirectLink">
                    <span>{t('auth.login.noAccount')}</span>
                    <button
                        className="link strong"
                        onClick={() => updateUrlParams({ open: AUTH_CONSTANTS.SIGNUP })}
                        type="button"
                    >
                        {t('auth.login.switchToSignup')}
                    </button>
                </div>
            </form>
        </>
    );
};

export default reduxForm<LoginFormFields>({
    form: 'loginForm',
})(LoginForm);
