import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './style.scss';

interface FrontPageProps {
    date: string;
    user: {
        name: string;
        surname: string;
    };
}

const FrontPage: FunctionComponent<FrontPageProps> = ({ date, user: { name, surname } }) => {
    const { t } = useTranslation();

    return (
        <div className="pdf-front-page__container">
            <div className="pdf-front-page__content">
                <div className="pdf-front-page__header">
                    <img alt="Jobteaser logo" className="jobteaser-logo" src="/assets/pdf-jobteaser-logo.svg" />
                    <img alt="First page shape" src="/assets/pdf-home-shape.svg" />
                </div>
                <div className="pdf-front-page__text-container">
                    <h1>
                        {surname} {name}
                    </h1>
                    <span className="date">{date}</span>
                    <p className="subtitle">
                        <Trans i18nKey="pdf.frontPage.text" />
                    </p>
                </div>
                <div className="pdf-front-page__freya-logo-container">
                    <img alt="Freya logo" src="/assets/pdf-freya-logo.svg" />
                </div>
            </div>
            <div className="pdf-front-page__footer">
                <span>{t('pdf.frontPage.footer')}</span>
            </div>
        </div>
    );
};

export default FrontPage;
