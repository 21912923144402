import { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/common/Modal';

interface InstructionProps {
    children: ReactNode;
    onClose: VoidFunction;
}

const Instructions: FunctionComponent<InstructionProps> = ({ children, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal closeModal={onClose} title={t('instruction.title')} fullScreen>
            <div className="base-margin">
                {children}
                <button className="base-margin button button--black button--block" onClick={onClose} type="button">
                    {t('instruction.start')}
                </button>
            </div>
        </Modal>
    );
};

export default Instructions;
