import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { SubmissionError } from 'redux-form';
import { SocioDemoFormFields } from 'components/SocioDemoForm';
import { RootState } from 'store/store';
import { Delete, Get, Patch } from 'utils/http';

export interface UserStateDefined {
    id: string;
    email: string;
    formCompleted: boolean;
    language: string;
    lastFormCompleteId: string | null;
    name: string;
    surname: string;
    rotiCompleted: boolean;
}

type UserState = UserStateDefined | null;

const initialState = null as UserState;

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<UserState>) => action.payload,
        setUserLanguage: (state, action) => {
            if (state) {
                state.language = action.payload;
            }
        },
        cleanUser: () => initialState,
    },
});

export const deleteAccount = (API_URL: string) => async () => {
    try {
        const response = await Delete(`${API_URL}/user/`);

        if (response.success) {
            return true;
        }

        return null;
    } catch (e) {
        console.error(e);

        return null;
    }
};

export const getUser =
    (API_URL: string): ThunkAction<void, RootState, void, AnyAction> =>
    async (dispatch) => {
        try {
            const user = await Get(`${API_URL}/user`);
            dispatch(userSlice.actions.setUser(user));
        } catch (e) {
            throw new SubmissionError({ _error: 'error.server.unknown' });
        }
    };

export const updateUser =
    (API_URL: string, values: SocioDemoFormFields): ThunkAction<void, RootState, void, AnyAction> =>
    async (dispatch) => {
        try {
            const user = await Patch(`${API_URL}/user/`, { values });
            dispatch(userSlice.actions.setUser(user));
        } catch (e) {
            throw new SubmissionError({ _error: 'error.server.unknown' });
        }
    };

export const updateUserLanguage =
    (API_URL: string, newLanguage: string): ThunkAction<void, RootState, void, AnyAction> =>
    async (dispatch) => {
        try {
            await Patch(`${API_URL}/user/language`, { language: newLanguage });
            dispatch(userSlice.actions.setUserLanguage(newLanguage));
        } catch (e) {
            throw new SubmissionError({ _error: 'error.server.unknown' });
        }
    };

export const { cleanUser } = userSlice.actions;

export default userSlice.reducer;
