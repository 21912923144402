import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import Button from 'components/common/Button';
import SimpleField from 'components/common/form/SimpleField';
import { useUrlParams } from 'hooks/useUrlParams';
import { AUTH_CONSTANTS } from 'utils/constants';
import { emailFormat, required } from 'utils/validators';

export interface LostPasswordFormFields {
    email: string;
}

const PasswordLostForm: FunctionComponent<InjectedFormProps<LostPasswordFormFields>> = ({
    error,
    handleSubmit,
    submitting,
    valid,
}) => {
    const { t } = useTranslation();
    const updateUrlParams = useUrlParams();

    return (
        <>
            <h1 className="title">{t('auth.lostPassword.title')}</h1>
            <p className="subtitle">{t('auth.lostPassword.subtitle')}</p>
            <form className="base-margin" method="post" onSubmit={handleSubmit}>
                {error && <div className="error">{error}</div>}
                <Field
                    component={SimpleField}
                    label={t('auth.email.label')}
                    name="email"
                    type="email"
                    validate={[required, emailFormat]}
                />
                <Button
                    classes="button--block button--black base-margin"
                    disabled={!valid}
                    submitting={submitting}
                    text={t('auth.lostPassword.submit')}
                    submit
                />
                <div className="base-margin">
                    <button
                        className="link text--medium"
                        onClick={() => updateUrlParams({ open: AUTH_CONSTANTS.LOGIN })}
                        type="button"
                    >
                        {t('auth.lostPassword.switchToLogin')}
                    </button>
                </div>
            </form>
        </>
    );
};

export default reduxForm<LostPasswordFormFields>({
    form: 'PasswordLostForm',
})(PasswordLostForm);
