export const AUTH_CONSTANTS = {
    LOGIN: 'login',
    SIGNUP: 'signup',
    LOSTPASSWORD: 'lost-password',
};

export const USER_ROLES = {
    ADMIN: 'admin',
};

export const AGE_CAT = ['< 20', '20 - 24', '25 - 34', '35 - 49', '50 - 65', '65 <'];

export const DIPLOMA_LIST = [
    {
        name: 'sociodemo.diploma.master',
        key: 'master',
    },
    {
        name: 'sociodemo.diploma.two_years_in_university',
        key: 'twoYearsUniversity',
    },
    {
        name: 'sociodemo.diploma.none',
        key: 'none',
    },
    {
        name: 'sociodemo.diploma.a_levels',
        key: 'aLevels',
    },
    {
        name: 'sociodemo.diploma.phd',
        key: 'phd',
    },
    {
        name: 'sociodemo.diploma.engineer',
        key: 'engineer',
    },
];

export const GENDER_LIST = [
    {
        name: 'sociodemo.gender.female',
        key: 'female',
    },
    {
        name: 'sociodemo.gender.male',
        key: 'male',
    },
    {
        name: 'sociodemo.gender.other',
        key: 'other',
    },
];

export const JOBS_LIST = [
    {
        name: 'sociodemo.job.public_excluding_care',
        key: 'publicExcludingCare',
    },
    {
        name: 'sociodemo.job.care_establishment',
        key: 'careEstablishment',
    },
    {
        name: 'sociodemo.job.business_service_establishment',
        key: 'businessOrServiceEstablishment',
    },
    {
        name: 'sociodemo.job.crafts',
        key: 'crafts',
    },
    {
        name: 'sociodemo.job.self_entrepreneurship',
        key: 'selfEntrepreneurship',
    },
    {
        name: 'sociodemo.job.worker_at_the_show',
        key: 'workerAtTheShow',
    },
    {
        name: 'sociodemo.job.ngo',
        key: 'ngo',
    },
    {
        name: 'sociodemo.job.start_up',
        key: 'startUp',
    },
    {
        name: 'sociodemo.job.small_medium_business',
        key: 'smallOrMediumBusiness',
    },
    {
        name: 'sociodemo.job.big_business',
        key: 'bigBusiness',
    },
    {
        name: 'sociodemo.job.digital_services_company',
        key: 'digitalServicesCompany',
    },
    {
        name: 'sociodemo.job.cabinet',
        key: 'cabinet',
    },
];

export const REASONS_LIST = [
    {
        name: 'sociodemo.reason.knowledge',
        key: 'knowledge',
    },
    {
        name: 'sociodemo.reason.recommendation',
        key: 'recommendation',
    },
    {
        name: 'sociodemo.reason.curiosity',
        key: 'curiosity',
    },
    {
        name: 'sociodemo.reason.institution',
        key: 'institution',
    },
    {
        name: 'sociodemo.reason.orientation',
        key: 'orientation',
    },
];

export const STATUS_LIST = [
    {
        name: 'sociodemo.status.lycee',
        key: 'lycee',
    },
    {
        name: 'sociodemo.status.student',
        key: 'student',
    },
    {
        name: 'sociodemo.status.employee',
        key: 'employee',
    },
    {
        name: 'sociodemo.status.freelance',
        key: 'freelance',
    },
    {
        name: 'sociodemo.status.direction',
        key: 'direction',
    },
    {
        name: 'sociodemo.status.unemployed',
        key: 'unemployed',
    },
];

export const ROTI_QUESTIONS = ['satisfaction', 'difficulty', 'aestheticism', 'duration', 'content'];

export const ROTI_ANSWERS = [1, 2, 3, 4, 5, 6, 7];
