import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/common/Modal';

interface PauseConfirmationProps {
    onClose: VoidFunction;
    push: (path: string) => void;
}

const PauseConfirmation: FunctionComponent<PauseConfirmationProps> = ({ onClose, push }) => {
    const { t } = useTranslation();

    return (
        <Modal closeModal={onClose} title={t('navigation.pause.title')} fullScreen>
            <div className="base-margin">
                <p>{t('navigation.pause.subtitle')}</p>
                <button className="button button--block button--grey base-margin" onClick={onClose} type="button">
                    {t('navigation.pause.no')}
                </button>
                <button
                    className="button button--block button--black base-margin"
                    onClick={() => {
                        push('/account');
                        onClose();
                    }}
                    type="button"
                >
                    {t('navigation.pause.confirm')}
                </button>
            </div>
        </Modal>
    );
};

export default PauseConfirmation;
