import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Button from 'components/common/Button';
import { DimensionResult } from 'components/Diagram';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import MainWithCard from 'components/layout/MainWithCard';
import Panel from 'components/layout/Panel';
import PdfModal from 'components/PdfModal';
import Restitution from 'components/Restitution';
import DescriptionResults from 'components/StepDescription/DescriptionResults';
import { useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { Get } from 'utils/http';

const Results = () => {
    const { t } = useTranslation();
    const { API_URL } = useAppData();
    const [loading, setLoading] = useState(true);
    const [formId, setFormId] = useState<string | undefined>(undefined);
    const [higherDimension, setHigherDimension] = useState<string | undefined>(undefined);
    const [openPdfModal, setOpenPdfModal] = useState(false);
    const push = useLocalizedHistoryPush();

    const currentUser = useAppSelector(({ user }) => user);

    useEffect(() => {
        (async () => {
            const { dimensionResults, id }: { dimensionResults: DimensionResult[]; id: string } = await Get(
                `${API_URL}/form/last_complete_form`
            );
            const maxGrade = Math.max(...dimensionResults.map((d) => d.grade));
            const dimensionResult = dimensionResults.find((d) => d.grade === maxGrade)!;

            setHigherDimension(dimensionResult.dimension);
            setFormId(id);
            setLoading(false);
        })();
    }, []);

    const onDownloadPdfClick = ({ language }: { language: string }) => {
        setOpenPdfModal(false);

        const url = `${API_URL}/pdf/download/${formId}/${language}`;
        window.open(url, '_blank');
    };

    if (!currentUser?.lastFormCompleteId) {
        return (
            <Container>
                <Panel />
                <Main>
                    <MainWithCard header={<div />} title={t('results.title')}>
                        <p className="center">{t('restitution.empty.text')}</p>
                        <Button
                            classes="button--black button--center base-margin"
                            onClick={() => push('/account')}
                            text={t('restitution.empty.button')}
                        />
                    </MainWithCard>
                </Main>
            </Container>
        );
    }

    if (!currentUser.formCompleted) {
        return <Redirect to="/form" />;
    }

    if (loading) {
        return null;
    }

    return (
        <Container>
            {openPdfModal && <PdfModal onClose={() => setOpenPdfModal(false)} onSubmit={onDownloadPdfClick} />}
            <Panel>
                <DescriptionResults formId={formId!} onOpenPdfModalClick={() => setOpenPdfModal(true)} />
            </Panel>
            <Main>
                <MainWithCard header={<div />} title={t('results.title')}>
                    <Restitution
                        formId={formId!}
                        higherDimension={higherDimension!}
                        onOpenPdfModalClick={() => setOpenPdfModal(true)}
                    />
                </MainWithCard>
            </Main>
        </Container>
    );
};

export default Results;
