import { useTranslation } from 'react-i18next';
import './style.scss';

const DescriptionInterstep = () => {
    const { t } = useTranslation();

    return (
        <div className="descriptionInterstep">
            <h3 className="title title--small">{t('interstep.menu.title')}</h3>
            <img alt="waiting" src="/assets/waiting.svg" />
        </div>
    );
};

export default DescriptionInterstep;
