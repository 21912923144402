import { FunctionComponent } from 'react';
import './style.scss';

const Spinner: FunctionComponent<{ color?: string }> = ({ color }) => (
    <div className={`lds-ellipsis ${color ? `lds-ellipsis--${color}` : ''}`}>
        <div />
        <div />
        <div />
        <div />
    </div>
);

export default Spinner;
