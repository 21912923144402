import { FunctionComponent } from 'react';
import './style.scss';

const StepIcon: FunctionComponent<{ todo?: boolean }> = ({ todo }) => (
    <div className={`step ${todo ? 'step--todo' : 'step--done'}`}>
        {todo ? <img alt="icon" src="/assets/icons/lock.svg" /> : <img alt="icon" src="/assets/icons/check.svg" />}
    </div>
);

export default StepIcon;
