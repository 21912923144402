import downloadjs from 'downloadjs';
import { SubmissionError } from 'redux-form';
import { Get } from 'utils/http';

export const exportForms =
    (API_URL: string, { startDate, endDate, language }: { startDate: string; endDate: string; language: string }) =>
    async () => {
        try {
            const queryParams = `?startDate=${startDate}&endDate=${endDate}${language ? `&language=${language}` : ''}`;
            const response = await Get(`${API_URL}/admin/export${queryParams}`, null, { jsonify: false });
            if (!response) {
                throw new SubmissionError({ _error: 'error.server.unknown' });
            }
            const fileName = `export-freya_${Date.now()}.csv`;
            const file = await response.blob();

            return downloadjs(file, fileName);
        } catch (error) {
            if (error instanceof Error) {
                throw new SubmissionError({ _error: error.message });
            }
            throw error;
        }
    };

export default { exportForms };
