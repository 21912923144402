import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import Panel from 'components/layout/Panel';
import DescriptionAccount from 'components/StepDescription/DescriptionAccount';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { createForm, recoverForm } from 'store/slices/currentForm';
import './style.scss';

const Account = () => {
    const { API_URL } = useAppData();
    const { t } = useTranslation();
    const { currentFormId, user, progress } = useAppSelector((state) => state);
    const [createFormButtonSubmitting, setCreateFormButtonSubmitting] = useState(false);
    const push = useLocalizedHistoryPush();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(recoverForm(API_URL));
    }, []);

    const createNewForm = async () => {
        setCreateFormButtonSubmitting(true);
        await dispatch(createForm(API_URL));
        push('/step/first/1');
        setCreateFormButtonSubmitting(false);
    };

    return (
        <Container bgColor="white">
            <Panel>
                <DescriptionAccount />
            </Panel>
            <Main centered>
                {/* eslint-disable-next-line react/no-danger */}
                <h1 dangerouslySetInnerHTML={{ __html: t('account.title') }} className="title account__title" />
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={{ __html: t('account.text1') }} />
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={{ __html: t('account.text2') }} />
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={{ __html: t('account.text3') }} />
                <div className="main__buttons">
                    {user &&
                        progress.orderProgress !== undefined &&
                        currentFormId &&
                        currentFormId !== user.lastFormCompleteId && (
                            <Button
                                classes="button--white"
                                onClick={() =>
                                    push(
                                        `/step/${progress.firstStepComplete ? 'second' : 'first'}/${
                                            progress.orderProgress
                                        }`
                                    )
                                }
                                text={t('account.action.continue')}
                            />
                        )}
                    <Button
                        classes="button--black"
                        onClick={createNewForm}
                        submitting={createFormButtonSubmitting}
                        text={user?.lastFormCompleteId ? t('account.action.new') : t('account.action.first')}
                    />
                </div>
            </Main>
        </Container>
    );
};

export default Account;
