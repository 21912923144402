import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { MenuPlacement } from 'react-select';
import './style.scss';

interface SelectFieldProps {
    className?: string;
    hasAllOption?: boolean;
    input: {
        name?: string;
        onChange: (newValue: string) => void;
        value: string | null;
    };
    isClearable?: boolean;
    isSearchable?: boolean;
    label?: string;
    labelHidden?: boolean;
    meta?: {
        error?: string;
        touched: boolean;
        warning?: string;
    };
    options: {
        label: string;
        value: string;
    }[];
    placeholder?: string;
    menuPlacement?: MenuPlacement;
}

const SelectField: FunctionComponent<SelectFieldProps> = ({
    className = '',
    hasAllOption = false,
    input,
    isClearable,
    isSearchable = false,
    label,
    labelHidden = false,
    placeholder = '',
    meta,
    options = [],
    menuPlacement,
    ...props
}) => {
    const { touched = false, error = false, warning = false } = meta ?? {};
    const { t } = useTranslation();
    const [value, setValue] = useState<typeof options[number] | null>(
        options.find(({ value: optValue }) => optValue === input.value) ?? null
    );

    const handleChange = (newValue: typeof options[number] | null) => {
        setValue(newValue);
        if (newValue) {
            input.onChange(newValue.value);
        }
    };

    // TODO PRE : vérifier si un reset du formulaire est fait à un moment.
    /*
    // this is to force a clean value on redux form reset as per the bug described here :
    // https://stackoverflow.com/questions/50640858/react-select-does-not-clear-value-when-redux-form-is-reset
    useEffect(() => {
        if (input.value === '') {
            setValue('');
        }
    }, [input.value]);
 */

    return (
        <div
            className={`
                field
                ${touched && error ? 'field--error' : ''}
                ${touched && warning ? 'field--warning' : ''}
                ${className}
            `}
        >
            <label className={labelHidden ? 'field__label--hidden' : ''} htmlFor={input.name}>
                {label}
            </label>
            <Select
                {...props}
                className="select-container"
                classNamePrefix="select"
                isClearable={isClearable}
                isSearchable={isSearchable}
                menuPlacement={menuPlacement}
                name={input.name}
                onChange={handleChange}
                options={hasAllOption ? [{ value: '', label: t('select.all') }, ...options] : options}
                placeholder={placeholder}
                value={value}
            />
            {touched &&
                ((error && <span>{t(error)}</span>) ||
                    // eslint-disable-next-line react/no-danger
                    (warning && <span dangerouslySetInnerHTML={{ __html: t(warning) }} />))}
        </div>
    );
};

export default SelectField;
