import classnames from 'classnames';
import { FunctionComponent } from 'react';
import DescriptionExercise from 'components/StepDescription/DescriptionExercise';
import './style.scss';

const generateStep = (step: 'step1' | 'step2', currentStep: string) => {
    const hiddenDescription = step !== currentStep;

    return (
        <div className={classnames('panelDescription__step', { 'panelDescription__step--hidden': hiddenDescription })}>
            <DescriptionExercise hiddenDescription={hiddenDescription} step={step} type="short" />
        </div>
    );
};

const PanelDescription: FunctionComponent<{
    step: string;
}> = ({ step }) => (
    <div className="panelDescription">
        {generateStep('step1', step)}
        {generateStep('step2', step)}
    </div>
);

export default PanelDescription;
