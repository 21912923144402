import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { RootState } from 'store/store';

interface LanguageState {
    language: string | null;
    allowedLanguages: string[];
}

const initialState: LanguageState = {
    allowedLanguages: [],
    language: null,
};

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        },
        setAllowedLanguages: (state, action: PayloadAction<string[]>) => {
            state.allowedLanguages = action.payload;
        },
    },
});

export const languageSelector = (state: RootState) => state.language;

export const { setAllowedLanguages } = languageSlice.actions;

export const setLanguage =
    (newLanguage: string): ThunkAction<void, RootState, void, AnyAction> =>
    async (dispatch, getState) => {
        const { allowedLanguages } = getState().language;
        if (allowedLanguages.includes(newLanguage)) {
            i18n.changeLanguage(newLanguage);
        }
        dispatch(languageSlice.actions.setLanguage(newLanguage));
    };

export default languageSlice.reducer;
