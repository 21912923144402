import classnames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';
import './style.scss';

interface MainProps {
    children: ReactNode;
    className?: string;
    centered?: boolean;
}

const Main: FunctionComponent<MainProps> = ({ children, className = '', centered }) => (
    <main className={classnames('main', className, { 'main--centered': centered })}>{children}</main>
);

export default Main;
