import { mergeDeepRight } from 'ramda';

export class HttpError extends Error {
    private status: number;

    private response: Response;

    constructor(response: Response) {
        super(response.statusText);

        this.status = response.status;
        this.response = response;
    }
}

enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

interface HttpOptions extends RequestInit {
    jsonify: boolean;
    handleError: (error: Error) => unknown;
    throwOnError: boolean;
    headers: Record<string, string>;
}

const defaultOptions: HttpOptions = {
    // fetch options
    credentials: 'include',
    headers: {},
    // process options
    jsonify: true, // automatically parse JSON on successful response
    // error handling
    handleError: (error) => error, // hook to transform the error before being thrown, return null avoid it
    throwOnError: true, // throw an error whenever the response is not OK (as long as handleError does not return null)
};

const factory = (method: HttpMethod, customFactoryOptions = {}) => {
    const factoryOptions = mergeDeepRight(defaultOptions, customFactoryOptions);

    const customFetch = async (url: string, body: Object | null = null, customOptions = {}) => {
        const options = mergeDeepRight(factoryOptions, customOptions);

        const fetchOptions: RequestInit & { headers: Record<string, string> } = {
            method,
            headers: options.headers!,
            credentials: options.credentials,
        };

        if (body) {
            if (body instanceof FormData) {
                fetchOptions.body = body;
            } else {
                fetchOptions.body = JSON.stringify(body);
                fetchOptions.headers['Content-Type'] = 'application/json';
            }
        }

        const response = await fetch(url, fetchOptions);

        if (!response.ok) {
            const error = options.handleError(new HttpError(response));

            if (options.throwOnError && error !== null) {
                throw error;
            }
        }

        if (options.jsonify) {
            return response.json();
        }

        return response;
    };

    customFetch.overrideOptions = (newOptions: HttpOptions) =>
        factory(method, mergeDeepRight(factoryOptions, newOptions));

    return customFetch;
};

export const Get = factory(HttpMethod.GET);
export const Post = factory(HttpMethod.POST);
export const Patch = factory(HttpMethod.PATCH);
export const Delete = factory(HttpMethod.DELETE);
