import { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'hooks/store';
import useBrowserLanguage from 'hooks/useBrowserLanguage';
import { getUrlLanguage, getUrlWithoutLanguage } from 'hooks/useUrlParams';
import useUserLanguage from 'hooks/useUserLanguage';
import i18n from 'i18n';
import { languageSelector, setAllowedLanguages, setLanguage } from 'store/slices/language';
import { getAllLangs } from 'utils/lang';

const LanguageProvider = ({ children }: { children: ReactElement }) => {
    const { pathname } = useLocation();
    const { push } = useHistory();
    const userLanguage = useUserLanguage();
    const browserLanguage = useBrowserLanguage();
    const urlLanguage = getUrlLanguage(pathname);
    const dispatch = useAppDispatch();

    const { language } = useSelector(languageSelector);

    const allowedLanguages = getAllLangs().map(({ keyLang }) => keyLang);

    useEffect(() => {
        dispatch(setAllowedLanguages(allowedLanguages));
        dispatch(setLanguage(userLanguage ?? urlLanguage ?? browserLanguage));
    }, []);

    useEffect(() => {
        if (userLanguage) {
            dispatch(setLanguage(userLanguage));
            if (allowedLanguages.includes(userLanguage) && pathname === '/missing-language') {
                push(`/${userLanguage}/account`);
            } else if (pathname !== '/') {
                push(`/${userLanguage}/${getUrlWithoutLanguage(language!, pathname)}`);
            } else {
                push(`/${userLanguage}`);
            }
        }
    }, [userLanguage]);

    useEffect(() => {
        if (language && allowedLanguages.includes(language)) {
            i18n.changeLanguage(language);
        }
    }, [language]);

    useEffect(() => {
        if (urlLanguage && urlLanguage !== language) {
            dispatch(setLanguage(urlLanguage));
        }
    }, [urlLanguage]);

    // TODO PRE : Voir pour mettre un loading plutôt que rien
    if (!language) {
        return null;
    }

    return children;
};

export default LanguageProvider;
