import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

export interface SubDimensionResult {
    grade: number;
    order: number;
    subDimension: {
        dimension: string;
        key: string;
    };
}

interface SubResultProps {
    order: number;
    subDimensionResult: SubDimensionResult;
}
const SubResult: FunctionComponent<SubResultProps> = ({
    order,
    subDimensionResult: {
        grade,
        subDimension: { dimension, key: subDimension },
    },
}) => {
    const { t } = useTranslation();
    const width = `${grade * 10}%`;

    return (
        <div className="pdf-sub-result-item__container">
            <span className="pdf-sub-result-item__title--bold">{`${t('pdf.subresult.rank')} ${order} - `}</span>
            <span className="pdf-sub-result-item__title">{t(`pdf.subresult.${subDimension}.title`)}</span>
            <div className="pdf-sub-result-bar">
                <div
                    className={`pdf-sub-result-bar__content pdf-sub-result-bar__content--${dimension}`}
                    style={{
                        width,
                    }}
                />
            </div>
            <p className="pdf-sub-result-item__description">{t(`pdf.subresult.${subDimension}.description`)}</p>
        </div>
    );
};

export default SubResult;
