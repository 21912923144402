import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Diagram from 'components/Diagram';

import './style.scss';

interface PageResultsProps {
    dimensionKey: string;
    formId: string;
}

const PageResults: FunctionComponent<PageResultsProps> = ({ dimensionKey, formId }) => {
    const { t } = useTranslation();

    return (
        <div className="pdf-results-page__container">
            <div className="pdf-results-page__heading">
                <h2>{t('pdf.resultsPage.title')}</h2>
                <p>{t('pdf.resultsPage.subtitle')}</p>
            </div>
            <div className="pdf-results-page__content">
                <p>{t('pdf.resultsPage.value.surtitle')}</p>
                <h3>
                    {t(`restitution.${dimensionKey}.title1`)},&nbsp;
                    {t(`restitution.${dimensionKey}.title2`).toLowerCase()}
                </h3>
                <p>{t(`restitution.${dimensionKey}.text`)}</p>
                <div className="pdf-results-page__diagram-container">
                    <Diagram formId={formId} isPdfDiagram />
                </div>
                <p>{t('pdf.resultsPage.compagnies')}</p>
                <p>{t(`restitution.${dimensionKey}.compagnies.text`)}</p>
            </div>
            <div className="pdf-results-page__shape-container">
                <img alt="Results page shape" src="/assets/pdf-results-shape.svg" />
            </div>
        </div>
    );
};

export default PageResults;
