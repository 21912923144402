import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import SelectField from 'components/common/form/SelectField';
import SimpleField from 'components/common/form/SimpleField';
import { getAllLangs } from 'utils/lang';
import { required } from 'utils/validators';
import './style.scss';

export interface ExportFormFields {
    startDate: string;
    endDate: string;
    language: string;
}

const ExportForm: FunctionComponent<InjectedFormProps<ExportFormFields>> = ({
    error,
    handleSubmit,
    submitting,
    valid,
}) => {
    const { t } = useTranslation();

    return (
        <form className="base-margin export-form" method="post" onSubmit={handleSubmit}>
            <h2 className="title title--medium">{t('admin.export.title')}</h2>
            {error && <div className="error">{t(`admin.export.error.${error}`)}</div>}
            <div className="dates">
                <Field
                    component={SimpleField}
                    label={t('admin.export.from')}
                    name="startDate"
                    type="date"
                    validate={required}
                />
                <Field
                    component={SimpleField}
                    label={t('admin.export.to')}
                    name="endDate"
                    type="date"
                    validate={required}
                />
            </div>
            <div className="language">
                <Field
                    component={SelectField}
                    label={t('admin.export.language')}
                    name="language"
                    options={getAllLangs().map(({ keyLang, label }) => ({ value: keyLang, label }))}
                    hasAllOption
                />
            </div>
            <div className="submit-button">
                <button className="button button--black" disabled={!valid || submitting} type="submit">
                    {t('admin.export.submit')}
                </button>
            </div>
        </form>
    );
};

export default reduxForm<ExportFormFields>({
    form: 'ExportForm',
})(ExportForm);
