import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { reducer as form } from 'redux-form';
import auth from 'jt-client/reducers/auth';

/* eslint-disable import/no-cycle */
import currentFormId from 'store/slices/currentForm';
import equalityOrdering from 'store/slices/equalityOrdering';
import gradeEqualities from 'store/slices/gradeEqualities';
import language from 'store/slices/language';
import menu from 'store/slices/menu';
import progress from 'store/slices/progress';
import replies from 'store/slices/replies';
import user from 'store/slices/user';
/* eslint-enable import/no-cycle */

const store = configureStore({
    reducer: {
        auth,
        currentFormId,
        form,
        equalityOrdering,
        gradeEqualities,
        progress,
        replies,
        user,
        language,
        menu,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: {
                ignoredPaths: ['form.profilingForm', 'user.ageRange'], // Because of Redux Form, just to disable the error
            },
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;

export default store;
