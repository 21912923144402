import { FunctionComponent, HTMLProps, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import ReactSlider from 'react-slider';
import DescriptionExercise from 'components/StepDescription/DescriptionExercise';
import Instructions from 'components/StepDescription/Instructions';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import { postReply } from 'store/slices/replies';
import './style.scss';

const InstructionModal = ({ onClose }: { onClose: VoidFunction }) => (
    <Instructions onClose={onClose}>
        <DescriptionExercise step="step1" />
    </Instructions>
);

interface ExerciseOneProps {
    order: number;
}

const ExerciseOne: FunctionComponent<ExerciseOneProps> = ({ order }) => {
    const [sliderValue, setValue] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);
    const { API_URL } = useAppData();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const push = useLocalizedHistoryPush();

    const reply = useAppSelector(({ replies }) => replies!.find(({ order: replyOrder }) => replyOrder === order));
    const isLast = useAppSelector(({ replies }) => replies!.length === order);

    const showInstructions = () => {
        confirmAlert({
            customUI: InstructionModal,
        });
    };

    useEffect(() => {
        setValue(reply?.value ?? 5);
        setIsUpdated(reply?.value !== null);
    }, [reply?.value]);

    useEffect(() => {
        if (order === 1) {
            showInstructions();
        }
    }, [order]);

    let timer: ReturnType<typeof setTimeout>;

    const renderTooltip = (props: HTMLProps<HTMLDivElement>, state: { valueNow: number }) => (
        <div {...props}>{isUpdated && <div className="slider__value">{state.valueNow}</div>}</div>
    );

    const sendResponse = async (val: number) => {
        await dispatch(postReply(API_URL, order, val));
        if (isLast) {
            push('/interstep');
        } else {
            push(`/step/first/${order + 1}`);
        }
    };

    const handleSlide = (val: number) => {
        timer = setTimeout(() => sendResponse(val), 500);
    };

    const cleanTimeOut = () => {
        clearTimeout(timer);
    };

    return (
        <div className="slider__container">
            <div className="slider__wrapper">
                <div className="slider__shadow">
                    <section className="slider__card">
                        <h2
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: t(`question.${reply?.question.key}`) }}
                            className="slider__title"
                        />
                        <div>
                            <ReactSlider
                                className="slider"
                                defaultValue={sliderValue}
                                markClassName="slider__mark"
                                max={10}
                                min={0}
                                onAfterChange={(val) => handleSlide(val)}
                                onChange={(val) => {
                                    cleanTimeOut();
                                    setValue(val);
                                    setIsUpdated(true);
                                }}
                                onSliderClick={(val) => {
                                    cleanTimeOut();
                                    setValue(val);
                                    handleSlide(val);
                                    setIsUpdated(true);
                                }}
                                renderThumb={(props, state) => renderTooltip(props, state)}
                                thumbClassName="slider__thumb"
                                trackClassName={!isUpdated ? 'slider__track slider__track__inactive' : 'slider__track'}
                                value={sliderValue}
                                marks
                            />
                            <div className="slider__legend">
                                <span>{t('step1.slider.notImportant')}</span>
                                <span>{t('step1.slider.neutral')}</span>
                                <span>{t('step1.slider.important')}</span>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default ExerciseOne;
