import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LanguageSwitcher from 'components/common/LanguageSwitcher';
import { useAppData } from 'hooks/useAppData';
import useWindowSize from 'hooks/useWindowSize';
import { languageSelector } from 'store/slices/language';
import './style.scss';

const Footer = () => {
    const { t } = useTranslation();
    const { language } = useSelector(languageSelector);
    const isMobile = 'xs' === useWindowSize().size;
    const { INNOVATION_SITE_URL } = useAppData();

    return (
        <footer className="footer" role="contentinfo">
            <div className="footer__content">
                {isMobile ? (
                    <>
                        <img alt="Logo Job Teaser" src="/assets/logo_JobTeaser_white.svg" />
                        <LanguageSwitcher className="language-switcher__small" />
                        <div className="footer__separator" />
                    </>
                ) : undefined}
                <div className="footer__content--left">
                    {language !== 'de' ? (
                        <a
                            className="footer__link"
                            href={INNOVATION_SITE_URL}
                            rel="noopener noreferrer"
                            target="_blank"
                            title={t('general.footer.innovation_site')}
                        >
                            {t('general.footer.innovation_site')}
                        </a>
                    ) : undefined}
                    <Link className="footer__link" title={t('general.legal_mentions')} to={`/${language}/legalnotices`}>
                        {t('general.legal_mentions')}
                    </Link>
                    <Link className="footer__link" title={t('general.terms_of_service')} to={`/${language}/tos`}>
                        {t('general.terms_of_service')}
                    </Link>
                    <Link
                        className="footer__link"
                        title={t('general.privacy_policy')}
                        to={`/${language}/confidentiality`}
                    >
                        {t('general.privacy_policy')}
                    </Link>
                </div>
                {isMobile ? <div className="footer__separator" /> : undefined}
                <div>
                    <span>{t('general.footer.copyright')}</span>
                    {isMobile ? <br /> : ' - '}
                    <span>{t('general.footer.copyrightDetails')}</span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
