import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LanguageSwitcher from 'components/common/LanguageSwitcher';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { createForm } from 'store/slices/currentForm';
import { setMenuOpen } from 'store/slices/menu';
import './style.scss';

const Menu = () => {
    const { API_URL } = useAppData();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        currentFormId,
        user,
        progress: { orderProgress, firstStepComplete },
    } = useAppSelector((state) => state);

    const createNewForm = async () => {
        await dispatch(createForm(API_URL));
        dispatch(setMenuOpen(false));
    };

    return (
        <div className="menu">
            <h1 className="menu__title">{t('menu.title', { surname: user?.surname })}</h1>
            {user && <LanguageSwitcher color="black" />}
            <ul className="menu__inner">
                {!(currentFormId && user && currentFormId !== user.lastFormCompleteId) && (
                    <li className="menu__link">
                        <Link
                            onClick={() => {
                                dispatch(setMenuOpen(false));
                            }}
                            title={t('menu.account')}
                            to="/account"
                        >
                            <img alt="chevron" src="/assets/icons/house.svg" />
                            {t('menu.account')}
                        </Link>
                    </li>
                )}
                {currentFormId && user && currentFormId !== user.lastFormCompleteId && (
                    <li className="menu__link">
                        <Link
                            onClick={() => {
                                dispatch(setMenuOpen(false));
                            }}
                            title={t('menu.continue')}
                            to={`/step/${firstStepComplete ? 'second' : 'first'}/${orderProgress}`}
                        >
                            <img alt="chevron" src="/assets/icons/pen.svg" />
                            {t('menu.continue')}
                        </Link>
                    </li>
                )}
                <li className="menu__link">
                    <Link onClick={createNewForm} title={t('menu.continue')} to="/step/first/1">
                        <img alt="chevron" src="/assets/icons/history.svg" />
                        {t('menu.restart')}
                    </Link>
                </li>
                {user?.lastFormCompleteId && user?.formCompleted && (
                    <li className="menu__link">
                        <Link
                            onClick={() => {
                                dispatch(setMenuOpen(false));
                            }}
                            title={t('menu.results')}
                            to="/results"
                        >
                            <img alt="chevron" src="/assets/icons/statistic.svg" />
                            {t('menu.results')}
                        </Link>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Menu;
