import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface MenuState {
    isOpen: boolean;
}

const initialState: MenuState = { isOpen: false };
export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenuOpen: (state, action: PayloadAction<boolean>) => ({ isOpen: action.payload }),
    },
});

export const isMenuOpenSelector = (state: RootState) => state.menu.isOpen;

export const { setMenuOpen } = menuSlice.actions;

export default menuSlice.reducer;
