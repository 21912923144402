import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Button from 'components/common/Button';
import StepIcon from 'components/common/StepIcon';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import Panel from 'components/layout/Panel';
import DescriptionInterstep from 'components/StepDescription/DescriptionInterstep';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import WithCurrentForm from 'providers/WithCurrentForm';
import WithGradeEqualities from 'providers/WithGradeEqualities';
import { cleanGradeEqualities } from 'store/slices/gradeEqualities';
import { resetProgress } from 'store/slices/progress';
import { resetRepliesValues } from 'store/slices/replies';
import './style.scss';

const Interstep = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { API_URL } = useAppData();
    const push = useLocalizedHistoryPush();
    const { secondStepComplete } = useAppSelector(({ progress }) => progress);

    if (secondStepComplete) {
        return <Redirect to="/final" />;
    }

    const resetStepOne = async () => {
        await dispatch(resetRepliesValues(API_URL));
        await dispatch(cleanGradeEqualities);
        await dispatch(resetProgress());
        push('/step/first/1');
    };

    const goToStepTwo = () => {
        push('/step/second/1');
    };

    return (
        <Container bgColor="white">
            <Panel>
                <DescriptionInterstep />
            </Panel>
            <Main centered>
                <div className="steps">
                    <StepIcon />
                    <StepIcon todo />
                </div>
                <h1 className="title">{t('interstep.title')}</h1>
                <p>{t('interstep.text')}</p>
                <div className="main__buttons">
                    <Button classes="button--white" onClick={resetStepOne} text={t('interstep.action.reset')} />
                    <Button classes="button--black" onClick={goToStepTwo} text={t('interstep.action.continue')} />
                </div>
            </Main>
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm>
        <WithGradeEqualities>
            <Interstep {...props} />
        </WithGradeEqualities>
    </WithCurrentForm>
);
