import { FunctionComponent } from 'react';
import SubResult, { SubDimensionResult } from 'components/pdf/PageSubResults/SubResult';

import './style.scss';

interface PageSubResultsProps {
    startingNumber: number;
    subDimensionResults: Array<SubDimensionResult>;
    withShape?: boolean;
}

const PageSubResults: FunctionComponent<PageSubResultsProps> = ({
    startingNumber,
    subDimensionResults,
    withShape = false,
}) => (
    <div className="pdf-sub-results-page__container">
        {subDimensionResults.map((result, index) => (
            <SubResult order={index + startingNumber} subDimensionResult={result} />
        ))}
        {withShape && (
            <div className="pdf-sub-results-page__shape-container">
                <img alt="Sub results page shape" src="/assets/pdf-sub-results-shape.svg" />
            </div>
        )}
    </div>
);

export default PageSubResults;
