import { useState, useEffect } from 'react';

const phone = 970;
const tablet = 1024;
const desktop = 1200;

function useWindowSize() {
    const isClient = 'object' === typeof window;

    function getSize() {
        let size;
        if (isClient) {
            const { innerWidth } = window;
            if (innerWidth < phone) {
                size = 'xs';
            } else if (innerWidth < tablet) {
                size = 'sm';
            } else if (innerWidth < desktop) {
                size = 'md';
            } else {
                size = 'lg';
            }
        }

        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
            size,
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);

        // eslint-disable-next-line consistent-return
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

export default useWindowSize;
