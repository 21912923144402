import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { Route, useHistory, RouteProps } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppData } from 'hooks/useAppData';
import useBrowserLanguage from 'hooks/useBrowserLanguage';
import { checkToken, currentUser } from 'jt-client/actions';
import { getUser } from 'store/slices/user';

interface AuthenticatedRouteProps {
    children?: ReactNode;
}

const AuthenticatedRoute: FunctionComponent<AuthenticatedRouteProps & RouteProps> = ({ children, ...rest }) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const { user } = useAppSelector((state) => state);
    const { API_URL } = useAppData();
    const history = useHistory();
    const browserLanguage = useBrowserLanguage();

    useEffect(() => {
        async function fetchToken() {
            try {
                const result = await dispatch(checkToken());
                if (!result || !result.success) {
                    history.push(`/${browserLanguage}`);
                } else {
                    await dispatch(currentUser());
                    if (!user) {
                        await dispatch(getUser(API_URL));
                    }
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false);
            }
        }
        fetchToken();
    }, []);

    if (loading) {
        return null;
    }

    return <Route {...rest}>{children}</Route>;
};

export default AuthenticatedRoute;
