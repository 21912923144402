import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Button from 'components/common/Button';
import StepIcon from 'components/common/StepIcon';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import Panel from 'components/layout/Panel';
import DescriptionInterstep from 'components/StepDescription/DescriptionInterstep';
import { useAppSelector } from 'hooks/store';
import { useLocalizedHistoryPush } from 'hooks/useUrlParams';
import WithCurrentForm from 'providers/WithCurrentForm';
import WithGradeEqualities from 'providers/WithGradeEqualities';
import './style.scss';

const Final = () => {
    const { t } = useTranslation();
    const push = useLocalizedHistoryPush();
    const hadSecondStep = useAppSelector(({ gradeEqualities }) => gradeEqualities.length !== 0);
    const { secondStepComplete } = useAppSelector(({ progress }) => progress);
    const userState = useAppSelector(({ user }) => user);

    if (!secondStepComplete) {
        return <Redirect to="/account" />;
    }

    const goToResults = () => {
        push('/results');
    };

    const goToForm = () => {
        push('/form');
    };

    return (
        <Container bgColor="white">
            <Panel>
                <DescriptionInterstep />
            </Panel>
            <Main centered>
                <div className="steps">
                    <StepIcon />
                    {hadSecondStep ? <StepIcon /> : null}
                </div>
                <h1 className="title">{t('final.title')}</h1>
                <p>{t('final.text1')}</p>
                <p>{t('final.text2')}</p>
                <div className="main__buttons">
                    {userState?.formCompleted && userState?.rotiCompleted ? (
                        <Button classes="button--black" onClick={goToResults} text={t('final.action.results')} />
                    ) : (
                        <Button classes="button--black" onClick={goToForm} text={t('final.action.form')} />
                    )}
                </div>
            </Main>
        </Container>
    );
};

export default ({ ...props }) => (
    <WithCurrentForm>
        <WithGradeEqualities>
            <Final {...props} />
        </WithGradeEqualities>
    </WithCurrentForm>
);
