import { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Footer from 'components/layout/Footer';
import './style.scss';

interface ErrorPageProps {
    children: ReactNode;
    content?: string;
    title: string;
}

const ErrorPage: FunctionComponent<ErrorPageProps> = ({ children, content, title }) => {
    const { t } = useTranslation();

    return (
        <div className="error-page__container">
            <header className="error-page-header__header">
                <Link title={t('general.account')} to="/">
                    <img alt="Logo" src="/assets/logo_freya.svg" width="107" />
                </Link>
            </header>
            <div className="error-page-main">
                <div className="error-page-main__content">
                    <div className="error-page-main__content--left">
                        <h1>{title}</h1>
                        <p>{content}</p>
                        {children}
                    </div>
                    <img alt="" aria-hidden="true" src="/assets/error.svg" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ErrorPage;
