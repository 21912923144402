import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

const DimensionFrame: FunctionComponent<{ dimensionKey: string; percentage?: number }> = ({
    dimensionKey,
    percentage,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`pdf-dimension-frame__container pdf-dimension-frame__container--${dimensionKey}`}>
            {percentage && <span className="pdf-dimension-frame__percentage">{`${percentage} %`}</span>}
            <span className="pdf-dimension-frame__name">{t(`restitution.${dimensionKey}.shortTitle`)}</span>
            <div className="pdf-dimension-frame__separator" />
            <span className="pdf-dimension-frame__text">{t(`restitution.${dimensionKey}.title2`)}</span>
        </div>
    );
};

export default DimensionFrame;
